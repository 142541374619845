import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Material, MaterialStateService, MaterialPriceType } from '../../../../../state';
import { CalsiumListConfig, CalsiumListSelectItem, ColumnDataType, CalsiumListColumnItem } from '../../../../calsiumlist/calsiumlist.component.model';
import { CalsiumDateFormatterPipe } from '../../../../shared/calsium-date.pipe';
import { CalsiumNumberFormatterPipe } from '../../../../shared/calsium-number.pipe';
import { Subscription, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import '../../../../shared/string-extensions';
import { ErrorHandlerService } from '../../../../errorhandler/error-handler.service';
import { ErrorTypeEnum, TextAlignment } from '../../../../shared/enums.model';
import { toNumber } from 'lodash';

@Component({
    selector: 'cal-material-prices',
    templateUrl: './material-prices.component.html',
    styleUrls: ['./material-prices.component.css']
})
export class MaterialPricesComponent implements OnInit, OnDestroy {

    @Input() material: Material;
    @Input() materialPriceTypes: Observable<MaterialPriceType[]>;
    @Input() resources: any;

    listConfig: CalsiumListConfig;
    subscriptions = new Subscription();
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    priceTypeColumn: CalsiumListColumnItem;
    constructor(private materialService: MaterialStateService,
        private errHandler: ErrorHandlerService) { }

    ngOnInit() {
        this.sortingExpression = this.materialService.getMaterialPricesSortingExpression();
        this.filterExpression = this.materialService.getMaterialPricesFilterExpression();
        this.subscriptions.add(
            combineLatest(this.sortingExpression, this.filterExpression).pipe(
                debounceTime(0),
                switchMap(([sort, filter]) => {
                    return this.materialService.loadMaterialById(toNumber(this.material.id), sort, filter);
                })
            ).subscribe()
        );
        this.buildList();
    }

    saveEditedItem(formGroup, selectedPrice) {
        const payload = {
            id: selectedPrice.id,
            materialId: selectedPrice.materialId,
            materialPriceTypeId: selectedPrice.materialPriceType.id,
            validFrom: formGroup.value.validFrom,
            validTo: formGroup.controls.validTo.value,
            modifierComments: formGroup.value.modifierComments,
            price: formGroup.value.price
        };
        if (payload.validTo <= new Date()) {
            this.errHandler.emitError(this.resources.ToDateShouldBeTomorrowOrLater, this.resources.Error, ErrorTypeEnum.Error);
            return;
        }
        this.materialService.addMaterialPrice(payload).then((errors) => {
            if (errors && errors.length) {
                let errorMessage = '';
                for (const error of errors) {
                    switch (error) {
                        case 'VALIDITY_OVERLAPS':
                            errorMessage += this.resources.OverlappingDates;
                            break;
                        case 'INVALID_OPERATION':
                            errorMessage += this.resources.OnlyStrategicPricesCanBeUpdated;
                            break;
                        case 'INVALID_VALID_TO_UPDATE':
                            errorMessage += this.resources.ToDateShouldBeTomorrowOrLater;
                            break;
                        case 'FUTURE_STRATEGIC_PRICES_CANNOT_BE_UPDATED':
                            errorMessage += this.resources.FutureStrategicPricesCannotBeUpdated;
                            break;
                    }
                }
                this.errHandler.emitError(this.resources.OverlappingDates, this.resources.Error, ErrorTypeEnum.Error);
            } else {
                this.errHandler.emitError(this.resources.PriceAddedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
            }
        });
        return of(true);
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    buildList() {
        const deletableStrategicPriceExpression = `materialPriceType.id==${environment.strategicPriceId}`;

        this.listConfig = new CalsiumListConfig({
            ShowListCaption: true,
            ListCaption: this.resources.MaterialPrices,
            ShowFilterRow: true,
            ShowSumRow: false,
            ParentComponent: this,
            AllowEdit: true,
            isRowEditable: this.isEditable,
            EditItemMethod: this.saveEditedItem
        });

        this.priceTypeColumn = this.listConfig.AddListColumn(ColumnDataType.list, 'materialPriceType', this.resources.PriceType);
        this.priceTypeColumn.AllowFilter = true;
        this.priceTypeColumn.Sortable = true;
        this.priceTypeColumn.ColumnName = 'materialPriceType.id';
        this.priceTypeColumn.DisplayColumnName = 'materialPriceType.name';
        this.subscriptions.add(
            this.materialPriceTypes.subscribe((types) => {
                this.priceTypeColumn.FilterOptions.FilterListItems = of(types.map(item =>
                    CalsiumListSelectItem.getInstance(item.id.toString(), item.name)
                ));
            })
        );
        this.priceTypeColumn.FilterOptions.AddEmptyDropDownListItem = false;

        let column = this.listConfig.AddListColumn(ColumnDataType.number, 'price', this.resources.Price);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(4, 4);
        column.TextAlignment = TextAlignment.Right;

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'validFrom', this.resources.ValidFrom);
        column.AllowFilter = true;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumDateFormatterPipe();

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'validTo', this.resources.ValidTo);
        column.AllowFilter = true;
        column.AllowEdit = true;
        column.Sortable = true;
        column.OnChange = this.OnValidToDateChange;
        column.DisplayPipe = new CalsiumDateFormatterPipe();

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'modifiedBy', this.resources.ModifiedBy);
        column.AllowFilter = false;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(ColumnDataType.date, 'modifiedOn', this.resources.ModifiedOn);
        column.AllowFilter = false;
        column.Sortable = true;
        column.DisplayPipe = new CalsiumDateFormatterPipe();

        column = this.listConfig.AddListColumn(ColumnDataType.string, 'modifierComments', this.resources.ModifierComments);
        column.AllowFilter = false;
        column.Sortable = false;
    }

    isEditable(price): boolean {
        let isEditable = false;
        const stringToday = new Date().toISOString();
        const todayStringDate = stringToday.substring(0, stringToday.indexOf('T'));
        if ((price.validTo >= todayStringDate || price.validTo == null) && price.materialPriceType.id === environment.strategicPriceId
            && price.validFrom <= todayStringDate) {
            isEditable = true;
        }
        return isEditable;
    }

    onSort(sortExpression: string) {
        const sortParts = sortExpression.split(' ');
        if (sortParts[0].startsWith('modified')) {
            const createdField = sortParts[0].replace('modified', 'created');
            const updatedField = sortParts[0].replace('modified', 'updated');
            this.materialService.sort(`${createdField} ${sortParts[1]}, ${updatedField} ${sortParts[1]}`);
        } else {
            this.materialService.sortMaterialPrices(sortExpression);
        }
    }

    OnValidToDateChange(sender: any, event: any) {
        if (event != null) {
            sender.Form.controls['validTo'].value = new Date(event.year, event.month - 1, event.day).toDateString();
        }
    }

    onFilter(filterExpression: string) {
        this.materialService.filterMaterialPrices(filterExpression);
    }

    onClearFilterAndSorting() {
        this.materialService.clearMaterialPricesFilterAndSorting();
    }
}
