import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { Loadable } from '../../common/loadable';
import {
    LoadConsumingPlanningAreaMappingsAction, ChangeResultsTotalCountAction, ChangeFilterExpressionAction,
    ChangeSortExpressionAction, ChangePageAction
} from './actions';
import { AppState } from '../../app.state';
import { Paging } from '../..';

import { ConsumingPlanningAreaMappingApiService } from '../../../api/services/consuming-planning-area-mapping-api.service';
import { ConsumingPlanningAreaMapping } from './state-models/consuming-planning-area-mapping';
import { ConsumingPlanningAreaMappingState } from './state';

@Injectable()
export class ConsumingPlanningAreaMappingStateService {

    constructor(
        private appStore: Store<AppState>,
        private appState: State<AppState>,
        private apiService: ConsumingPlanningAreaMappingApiService) { }

    @Loadable()
    async loadConsumingPlanningAreaMappings(paging: Paging, sortExpression: string, filterExpression: string): Promise<void> {

        const payload = {
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression
        };

        const consumingPlanningAreaMappings = await this.apiService.getConsumingPlanningAreaMappings(payload);
        this.appStore.dispatch(new LoadConsumingPlanningAreaMappingsAction(consumingPlanningAreaMappings.list));
        this.appStore.dispatch(new ChangeResultsTotalCountAction(consumingPlanningAreaMappings.totalCount));
    }

    @Loadable()
    async addConsumingPlanningAreaMapping(cpaMappingAddCpa: any): Promise<any> {
        const response = await this.apiService.addConsumingPlanningAreaMapping(cpaMappingAddCpa);
        if (response.errors) {
            const errors = response.errors.map((err: { message: any; }) => err.message);
            return errors;
        }

        const state = <ConsumingPlanningAreaMappingState>this.appState.getValue().admin.consumingPlanningAreaMapping;
        await this.loadConsumingPlanningAreaMappings(state.Paging, state.SortExpression, state.FilterExpression);
    }

    getConsumingPlanningAreaMappings(): Observable<ConsumingPlanningAreaMapping[]> {
        return this.appStore
            .select(state => state.admin.consumingPlanningAreaMapping.consumingPlanningAreaMappings).pipe(map(item => _.values(item)));
    }

    getTotalCount(): Observable<number> {
        return this.appStore.select(state => state.admin.consumingPlanningAreaMapping.TotalCount);
    }

    getPaging(): Observable<Paging> {
        return this.appStore.select(state => state.admin.consumingPlanningAreaMapping.Paging);
    }

    goToPage(page: number) {
        this.appStore.dispatch(new ChangePageAction(page));
    }

    getSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.admin.consumingPlanningAreaMapping.SortExpression);
    }

    sort(sortExpression: string) {
        this.appStore.dispatch(new ChangeSortExpressionAction(sortExpression));
    }

    getFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.admin.consumingPlanningAreaMapping.FilterExpression);
    }

    filter(filterExpression: string) {
        this.appStore.dispatch(new ChangeFilterExpressionAction(filterExpression));
    }

    clearFilterAndSorting() {
        [
            new ChangeFilterExpressionAction(null),
            new ChangeSortExpressionAction(null)
        ].forEach(action => this.appStore.dispatch(action));
    }
}
