import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CommonStateService, MaterialStateService, Material, MaterialPriceType } from '../../../../state';
import { combineLatest } from 'rxjs/operators';
import { toNumber } from 'lodash';

@Component({
    selector: 'cal-material-details',
    templateUrl: './material-details.component.html',
    styleUrls: ['./material-details.component.css']
})
export class MaterialDetailsComponent implements OnInit, OnDestroy {

    resources: any = this.commonService.getResources();
    material$: Observable<Material>;
    materialPriceTypes$: Observable<MaterialPriceType[]>;
    loading = true;
    subscriptions = new Subscription();
    showHint = true;

    constructor(private route: ActivatedRoute,
        private materialService: MaterialStateService,
        private commonService: CommonStateService) {
        this.materialService.loadMaterialPriceTypes();
        this.material$ = this.materialService.getSelectedMaterial();
        this.materialPriceTypes$ = this.materialService.getMaterialPriceTypes();
        this.subscriptions.add(
            this.material$.subscribe(() => (this.loading = false)));
    }

    ngOnInit() {
        this.subscriptions.add(
            this.route.params
                .pipe(combineLatest(this.material$, this.materialPriceTypes$))
                .subscribe(([params, mat]) => {
                    if (!mat || mat.id !== +params.id) {
                        this.loading = true;
                        this.materialService.loadMaterialById(toNumber(params.id));
                    }
                })
        );
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }

    onHintDismiss(): void {
        this.showHint = false;
    }
}
