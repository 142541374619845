import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Loadable } from '../common/loadable';
import { AppState } from '../app.state';
import {
    ChangeReplacementComponentListSortExpressionAction,
    ChangeReplacementComponentListFilterExpressionAction,
    ChangeReplacementComponentListPageAction,
    LoadReplacementComponentListPageAction,
    ChangeReplacementComponentListTotalCountAction
} from './action';

import { ComponentAllocation, Paging } from '..';
import { ReplacementComponentListState } from './state';
import { ComponentAllocationApiService } from 'src/api/services/component-allocation-api.service';
import { toNumber } from 'lodash';

@Injectable()
export class ReplacementComponentListStateService {
    private resources = require('../../state/common/resources.json');
    constructor(
        private componentAllocationApiService: ComponentAllocationApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>) { }

    @Loadable()
    async loadReplacementComponentList(
        allocationScaniaUnitId: number,
        from: string,
        to: string,
        paging: Paging,
        sortExpression: string,
        filterExpression: string,
        forceRefetch: boolean = false) {
        const payload = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            allocationScaniaUnit: toNumber(allocationScaniaUnitId),
            paging: paging,
            order: sortExpression,
            filter: filterExpression,
            isReplacementComponent: true
        };
        const replacementComponentList = await this.componentAllocationApiService.getReplacementComponentList(payload, forceRefetch);

        this.appStore.dispatch(new LoadReplacementComponentListPageAction(replacementComponentList.list));
        this.appStore.dispatch(new ChangeReplacementComponentListTotalCountAction(replacementComponentList.totalCount, replacementComponentList.errorsCount));
    }

    async refetchList(allocationScaniaUnitId: number, from: string, to: string): Promise<void> {
        const state = <ReplacementComponentListState>this.appState.getValue().replacementComponentLists;
        await this.loadReplacementComponentList(allocationScaniaUnitId, from, to, state.replacementComponentListPaging, state.replacementComponentListSortExpression, state.replacementComponentListFilterExpression, true);
    }

    getList(): Observable<ComponentAllocation[]> {
        return this.appStore.select(state => state.replacementComponentLists.replacementComponentList);
    }

    getSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.replacementComponentLists.replacementComponentListSortExpression);
    }

    sort(sortExpression: string) {
        this.appStore.dispatch(new ChangeReplacementComponentListSortExpressionAction(sortExpression));
    }

    getFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.replacementComponentLists.replacementComponentListFilterExpression);
    }

    filter(filterExpression: string) {
        this.appStore.dispatch(new ChangeReplacementComponentListFilterExpressionAction(filterExpression));
    }

    getPaging(): Observable<Paging> {
        return this.appStore.select(state => state.replacementComponentLists.replacementComponentListPaging);
    }

    goToPage(page: number) {
        this.appStore.dispatch(new ChangeReplacementComponentListPageAction(page));
    }

    getTotalCount(): Observable<number> {
        return this.appStore.select(state => state.replacementComponentLists.replacementComponentListTotalCount);
    }

    getErrorCount(): Observable<number> {
        return this.appStore.select(state => state.replacementComponentLists.replacementComponentListErrorsCount);
    }

    clearFilterAndSorting() {
        [
            new ChangeReplacementComponentListSortExpressionAction(null),
            new ChangeReplacementComponentListFilterExpressionAction(null)
        ].forEach(action => this.appStore.dispatch(action));
    }
}
