import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';
import _, { toNumber } from 'lodash';

import { Loadable } from '../common/loadable';
import { AppState } from '../app.state';
import {
    ChangeProducedComponentListSortExpressionAction,
    ChangeProducedComponentListFilterExpressionAction,
    ChangeProducedComponentListPageAction,
    LoadProducedWidgetAction,
    ChangeComponentSearchFormAction,
    LoadProducedComponentListAction,
    ChangeProducedComponentListTotalCountAction,
    LoadAllocatedWidgetAction,
    LoadInStockWidgetAction,
    LoadAllocatedComponentListAction,
    ChangeAllocatedComponentListTotalCountAction,
    LoadInStockComponentListAction,
    ChangeInStockComponentListTotalCountAction,
    ChangeAllocatedComponentListSortExpressionAction,
    ChangeInStockComponentListSortExpressionAction,
    ChangeAllocatedComponentListFilterExpressionAction,
    ChangeInStockComponentListFilterExpressionAction,
    ChangeAllocatedComponentListPageAction,
    ChangeInStockComponentListPageAction,
} from './actions';

import { Paging, AllocationSummary, ComponentAllocation, ComponentAllocationInput } from '..';
import { ComponentAllocationApiService } from 'src/api/services/component-allocation-api.service';
import { ComponentAllocationSearchForm } from './state-models/component-allocation-search-form';
import { ComponentAllocationState } from './state';
import { MasterDataState } from '../master-data/state';
import { CalsiumDateFormatterPipe } from 'src/app/shared/calsium-date.pipe';
import { ToastrHelperService } from 'src/app/shared/toastr-helper.service';

@Injectable()
export class ComponentAllocationStateService {
    private resources = require('../../state/common/resources.json');
    constructor(
        private apiService: ComponentAllocationApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>,
        private datePipe: CalsiumDateFormatterPipe) { }

    @Loadable()
    async loadComponentAllocationSummary(allocationScaniaUnitId: number, from: string, to: string, forceRefetch: boolean = false): Promise<void> {
        const payload = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            allocationScaniaUnit: toNumber(allocationScaniaUnitId)
        };

        const producedSummary = await this.apiService.getComponentsSummary('produced', payload, forceRefetch);
        this.appStore.dispatch(new LoadProducedWidgetAction(producedSummary));

        const allocatedSummary = await this.apiService.getComponentsSummary('allocated', payload, forceRefetch);
        this.appStore.dispatch(new LoadAllocatedWidgetAction(allocatedSummary));

        const stockSummary = await this.apiService.getComponentsSummary('stock', payload, forceRefetch);
        this.appStore.dispatch(new LoadInStockWidgetAction(stockSummary));
    }

    @Loadable()
    async loadComponentAllocationList(
        allocationStatus: string,
        allocationScaniaUnitId: number,
        from: string,
        to: string,
        paging: Paging,
        sortExpression: string,
        filterExpression: string,
        forceRefetch: boolean = false) {
        const payload = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            allocationScaniaUnit: toNumber(allocationScaniaUnitId),
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression,
        };
        const allocationList = await this.apiService.getComponentsList(allocationStatus, payload, forceRefetch);
        if (allocationStatus === 'produced') {
            this.appStore.dispatch(new LoadProducedComponentListAction(allocationList.list));
            this.appStore.dispatch(new ChangeProducedComponentListTotalCountAction(allocationList.totalCount, allocationList.errorsCount));
        }
        if (allocationStatus === 'allocated') {
            this.appStore.dispatch(new LoadAllocatedComponentListAction(allocationList.list));
            this.appStore.dispatch(new ChangeAllocatedComponentListTotalCountAction(allocationList.totalCount, allocationList.errorsCount));
        }
        if (allocationStatus === 'stock') {
            this.appStore.dispatch(new LoadInStockComponentListAction(allocationList.list));
            this.appStore.dispatch(new ChangeInStockComponentListTotalCountAction(allocationList.totalCount, allocationList.errorsCount));
        }
    }

    @Loadable()
    async allocate(allocationStatus: string, allocationScaniaUnitId: number, from: string, to: string, filterExpression: string, selectAll: boolean, includedItems: ComponentAllocationInput[], excludedItems: ComponentAllocationInput[]): Promise<any> {
        const payload = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            selectAll,
            includedItems,
            excludedItems,
            allocationStatus,
            allocationScaniaUnit: toNumber(allocationScaniaUnitId),
            filter: filterExpression
        };
        const response = await this.apiService.allocate(payload);
        if (response.errors) {
            const errors = response.errors.map((err: { message: any; }) => err.message);
            return { errors };
        }
        await this.refetchList(allocationScaniaUnitId, from, to, allocationStatus);
        const allocateTotal = _.get(response, 'data.componentAllocation.allocate.length', 0);
        return { success: allocateTotal };
    }

    @Loadable()
    async moveToStock(allocationScaniaUnitId: number, from: string, to: string, filterExpression: string, selectAll: boolean, includedItems: ComponentAllocationInput[], excludedItems: ComponentAllocationInput[], allocationStatus: string): Promise<number> {
        const payload = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            selectAll,
            includedItems,
            excludedItems,
            allocationScaniaUnit: toNumber(allocationScaniaUnitId),
            filter: filterExpression
        };
        const response = await this.apiService.moveToStock(payload);
        await this.refetchList(allocationScaniaUnitId, from, to, allocationStatus);
        return _.get(response, 'data.componentAllocation.moveToStock.length', 0);
    }

    async refetchList(allocationScaniaUnitId: number, from: string, to: string, allocationStatus: string): Promise<void> {
        await this.loadComponentAllocationSummary(allocationScaniaUnitId, from, to, true);

        const state = <ComponentAllocationState>this.appState.getValue().componentAllocation;
        await this.loadComponentAllocationList(allocationStatus, allocationScaniaUnitId, from, to, state.producedPaging, state.producedSortExpression, state.producedFilterExpression, true);
    }

    getList(type: string): Observable<ComponentAllocation[]> {
        return this.appStore.select(state => state.componentAllocation[`${type}ComponentList`]);
    }

    getSortingExpression(type: string): Observable<string> {
        return this.appStore.select(state => state.componentAllocation[`${type}SortExpression`]);
    }

    search(componentSearchForm: ComponentAllocationSearchForm) {
        this.appStore.dispatch(new ChangeComponentSearchFormAction(componentSearchForm));
    }

    getComponentSearchForm(): Observable<ComponentAllocationSearchForm> {
        return this.appStore.select(state => state.componentAllocation.searchForm);
    }

    sort(type: string, sortExpression: string) {
        if (type === 'produced') {
            this.appStore.dispatch(new ChangeProducedComponentListSortExpressionAction(sortExpression));
        }
        if (type === 'allocated') {
            this.appStore.dispatch(new ChangeAllocatedComponentListSortExpressionAction(sortExpression));
        }
        if (type === 'stock') {
            this.appStore.dispatch(new ChangeInStockComponentListSortExpressionAction(sortExpression));
        }
    }

    getFilterExpression(type: string): Observable<string> {
        return this.appStore.select(state => state.componentAllocation[`${type}FilterExpression`]);
    }

    filter(type: string, filterExpression: string) {
        if (type === 'produced') {
            this.appStore.dispatch(new ChangeProducedComponentListFilterExpressionAction(filterExpression));
        }
        if (type === 'allocated') {
            this.appStore.dispatch(new ChangeAllocatedComponentListFilterExpressionAction(filterExpression));
        }
        if (type === 'stock') {
            this.appStore.dispatch(new ChangeInStockComponentListFilterExpressionAction(filterExpression));
        }
    }

    getPaging(type: string): Observable<Paging> {
        return this.appStore.select(state => state.componentAllocation[`${type}Paging`]);
    }

    goToPage(type: string, page: number) {
        if (type === 'produced') {
            this.appStore.dispatch(new ChangeProducedComponentListPageAction(page));
        }
        if (type === 'allocated') {
            this.appStore.dispatch(new ChangeAllocatedComponentListPageAction(page));
        }
        if (type === 'stock') {
            this.appStore.dispatch(new ChangeInStockComponentListPageAction(page));
        }
    }

    getTotalCount(type: string): Observable<number> {
        return this.appStore.select(state => state.componentAllocation[`${type}TotalCount`]);
    }

    getErrorCount(type: string): Observable<number> {
        return this.appStore.select(state => state.componentAllocation[`${type}ErrorsCount`]);
    }

    getComponentsSummary(type: string): Observable<AllocationSummary> {
        return this.appStore.select(state => state.componentAllocation[`${type}Widget`]);
    }

    clearFilterAndSorting(type: string) {
        if (type === 'produced') {
            [
                new ChangeProducedComponentListSortExpressionAction(null),
                new ChangeProducedComponentListFilterExpressionAction(null)
            ].forEach(action => this.appStore.dispatch(action));
        }
        if (type === 'allocated') {
            [
                new ChangeAllocatedComponentListSortExpressionAction(null),
                new ChangeAllocatedComponentListFilterExpressionAction(null)
            ].forEach(action => this.appStore.dispatch(action));
        }
        if (type === 'stock') {
            [
                new ChangeInStockComponentListSortExpressionAction(null),
                new ChangeInStockComponentListFilterExpressionAction(null)
            ].forEach(action => this.appStore.dispatch(action));
        }
    }

    exportAllocationData(allocationStatus: string): Promise<any> {
        const state = <ComponentAllocationState>this.appState.getValue().componentAllocation;
        const searchForm = state.searchForm;
        const masterData = <MasterDataState>this.appState.getValue().masterData;
        const scaniaUnit = masterData.scaniaUnits.find(su => su.id === searchForm.selectedScaniaUnitId);
        const payload = {
            from: new Date(searchForm.fromDate).toISOString(),
            to: new Date(searchForm.toDate).toISOString(),
            allocationScaniaUnit: toNumber(searchForm.selectedScaniaUnitId)
        };
        const fileName = `Allocation_${scaniaUnit.name}_${payload.from}_${payload.to}`;

        return this.apiService.exportComponentAllocationList(allocationStatus, payload, fileName);
    }

    resetToDefaults() {
        const summary = { totalValue: 0, totalAmount: 0 };

        this.appStore.dispatch(new LoadProducedWidgetAction(summary));
        this.appStore.dispatch(new LoadAllocatedWidgetAction(summary));
        this.appStore.dispatch(new LoadInStockWidgetAction(summary));


        this.appStore.dispatch(new LoadProducedComponentListAction([]));
        this.appStore.dispatch(new ChangeProducedComponentListTotalCountAction(0, 0));

        this.appStore.dispatch(new LoadAllocatedComponentListAction([]));
        this.appStore.dispatch(new ChangeAllocatedComponentListTotalCountAction(0, 0));

        this.appStore.dispatch(new LoadInStockComponentListAction([]));
        this.appStore.dispatch(new ChangeInStockComponentListTotalCountAction(0, 0));
    }

    @Loadable()
    async deallocateByComponents(allocationStatus: string,
        allocationScaniaUnitId: number,
        from: string, to: string, response: number): Promise<boolean> {

        await this.refetchList(allocationScaniaUnitId, from, to, allocationStatus);
        //const allocateTotal = _.get(response, 'data.componentAllocation.allocate.length', 0);
        return true;
    }

}
