
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import gql from 'graphql-tag';
import { UnitOfMeasurement } from '../../state/master-data/state-models/unit-of-measurement';
import { map, lastValueFrom } from 'rxjs';
import { ComponentType } from 'src/state/master-data/state-models/component-type';
import { ManualAdjustment } from 'src/app/pva-manual-adjustment/manual-adjustment-model';
import { toNumber } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ManualAdjustmentApiService {
  constructor(private apollo: Apollo) { }

  async verify(input: ManualAdjustment[]): Promise<any> {

    let payload = input.map((item) => {
      return {
        componentTypeId: toNumber(item.componentTypeId),
        productIndividualNumber: item.productIndividualNumber,
        adjustment: toNumber(item.adjustment),
        adjustmentType: item.adjustmentType,
        comment: item.comment
      }
    });

    const mutation = `
          mutation ($payload: [ManualAdjustmentInput]!) {
            manualAdjustment {
                verify(payload: $payload) {
                    componentTypeId
                    productIndividualNumber
                    total
              }
            }
          }`;
    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    }).toPromise();
  }

  async confirm(input: ManualAdjustment[]): Promise<any> {

    let payload = input.map((item) => {
      return {
        componentTypeId: toNumber(item.componentTypeId),
        productIndividualNumber: item.productIndividualNumber,
        adjustment: toNumber(item.adjustment),
        adjustmentType: item.adjustmentType,
        comment: item.comment
      }
    });
    const mutation = `
          mutation ($payload: [ManualAdjustmentInput]!) {
            manualAdjustment {
                confirm(payload: $payload)
            }
          }`;
    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    }).toPromise();
  }
}