import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from 'src/state/app.state';
import { Loadable } from 'src/state/common/loadable';
import {
    AddCodeAction, LoadCodeListAction, ChangeCodeListSortExpressionAction,
    ChangeCodeListFilterExpressionAction, LoadPercentagesAction, AddPercentageAction,
    DeleteCodeAction, ChangeConditionsSortExpressionAction, ChangeConditionsFilterExpressionAction,
    LoadConditionsAction, LoadVariantFamilyAction, LoadVariantOptionAction, AddConditionVariantAction,
    LoadProductClassAction, LoadMarketAction, AddConditionAction, SaveConditionAction, RemoveConditionVariantAction,
    UpdateCodeAction, ChangeCodeShowOnlyValidCodesAction, ChangeConditionsShowOnlyValidConditionsAction, UpdateConditionsAction
} from './actions';
import { CurrencyAdjustmentCode, CurrencyAdjustmentCodeAdd } from './state-models/currency-adjustment-code';
import { CurrencyAdjustmentPercentage, CurrencyAdjustmentPercentageAdd } from './state-models/currency-adjustment-percentage';
import { CurrencyAdjustmentCondition, CurrencyAdjustmentConditionAdd, CurrencyAdjustmentConditionUpdate } from './state-models/currency-adjustment-condition';
import { CurrencyAdjustmentApiService } from 'src/api/services/currency-adjsutment-api.service';
import { VariantFamily } from 'src/state';
import { VariantApiService } from 'src/api/services/variant-api.service';
import { VariantOption } from 'src/state';
import { CurrencyAdjustmentConditionVariantOptionAdd, CurrencyAdjustmentConditionVariantOption } from './state-models/currency-adjustment-condition-variant-option';
import { ProductClassApiService } from 'src/api/services/product-class-api.service';
import { MarketApiService } from 'src/api/services/market-api.service';
import { ProductClass } from 'src/state';
import { Market } from 'src/state';
import { toNumber } from 'lodash';

@Injectable()
export class CurrencyAdjustmentStateService {
    constructor(
        private apiService: CurrencyAdjustmentApiService,
        private variantService: VariantApiService,
        private productClassService: ProductClassApiService,
        private marketService: MarketApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>
    ) { }

    @Loadable()
    async loadCode(
        sortExpression: string,
        filterExpression: string,
        showOnlyValidCodes: boolean
    ): Promise<void> {
        const payload = {
            showOnlyValidCodes,
            order: sortExpression,
            filter: filterExpression,
        };
        const response = await this.apiService.getCodeList(payload);
        this.appStore.dispatch(new LoadCodeListAction(response));
    }


    showOnlyValidCodes(showOnlyValidCodes: boolean) {
        this.appStore.dispatch(new ChangeCodeShowOnlyValidCodesAction(showOnlyValidCodes));
    }

    getShowOnlyValidCodes(): Observable<boolean> {
        return this.appStore.select(state => state.admin.currencyAdjustmentState.showOnlyValidCodes);
    }

    showOnlyValidConditions(showOVCondtions: boolean) {
        this.appStore.dispatch(new ChangeConditionsShowOnlyValidConditionsAction(showOVCondtions));
    }

    getshowOnlyValidConditions(): Observable<boolean> {
        return this.appStore.select(state => state.admin.currencyAdjustmentState.showOnlyValidConditions);
    }

    getCodeList(): Observable<CurrencyAdjustmentCode[]> {
        return this.appStore.select(
            (state) => state.admin.currencyAdjustmentState.codeList
        );
    }

    getCodeListSortingExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.currencyAdjustmentState.codeListSortExpression
        );
    }

    getCodeListFilterExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.currencyAdjustmentState.codeListFilterExpression
        );
    }

    sortCodeList(sortExpression: string) {
        this.appStore.dispatch(
            new ChangeCodeListSortExpressionAction(sortExpression)
        );
    }

    filterCodeList(filterExpression: string) {
        this.appStore.dispatch(
            new ChangeCodeListFilterExpressionAction(filterExpression)
        );
    }

    clearCodeListFilterAndSorting() {
        this.sortCodeList(null);
        this.filterCodeList(null);
    }

    async addCode(
        currencyAdjustmentCode: CurrencyAdjustmentCodeAdd
    ): Promise<any> {

        var payload = {
            ...currencyAdjustmentCode,
            validFrom: new Date(currencyAdjustmentCode.validFrom).toISOString(),
            validTo: currencyAdjustmentCode.validTo ? new Date(currencyAdjustmentCode.validTo).toISOString() : null,
        }

        const response = await this.apiService.addCode(payload);
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        this.appStore.dispatch(
            new AddCodeAction(response.data.currencyAdjustment.addCode)
        );
    }

    async updateCode(currencyAdjustmentCodeAdd: CurrencyAdjustmentCodeAdd): Promise<any> {
        let addValidDateTo: CurrencyAdjustmentCodeAdd[];
        var payload = {
            ...currencyAdjustmentCodeAdd,
            validFrom: new Date(currencyAdjustmentCodeAdd.validFrom).toISOString(),
            validTo: currencyAdjustmentCodeAdd.validTo ? new Date(currencyAdjustmentCodeAdd.validTo).toISOString() : null,
        }
        const response = await this.apiService.updateCode(payload);
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        this.appStore.dispatch(new UpdateCodeAction(response.data.currencyAdjustment.updateCode));
    }

    async updateConditions(currencyAdjustmentConditionUpdate: any): Promise<any> {
        const response = await this.apiService.updateConditions(currencyAdjustmentConditionUpdate);
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        this.appStore.dispatch(new UpdateConditionsAction(response.data.currencyAdjustment.updateConditions));
    }

    async deleteCode(codeId: number): Promise<any> {
        const response = await this.apiService.deleteCode(codeId);
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        const result = response.data.currencyAdjustment.deleteCode;
        if (result === true)
            this.appStore.dispatch(new DeleteCodeAction(codeId));
    }

    async getCodeById(id: number): Promise<CurrencyAdjustmentCode> {
        let list = <CurrencyAdjustmentCode[]>(
            this.appState.getValue().admin.currencyAdjustmentState.codeList
        );
        list = list.filter((x) => x.id == id);
        if (list.length == 1) return Promise.resolve(list[0]);
        else {
            const payload = {
                id: id,
            };
            const response = await this.apiService.getCodeList(payload);
            return response[0];
        }
    }

    async addPercentage(
        currencyAdjustmentPercentage: CurrencyAdjustmentPercentageAdd
    ): Promise<any> {

        var payload = {
            currencyAdjustmentCodeId: toNumber(currencyAdjustmentPercentage.currencyAdjustmentCodeId),
            percentage: toNumber(currencyAdjustmentPercentage.percentage),
            validFrom: new Date(currencyAdjustmentPercentage.validFrom).toISOString(),
            validTo: currencyAdjustmentPercentage.validTo ? new Date(currencyAdjustmentPercentage.validTo).toISOString() : null,
        }

        const response = await this.apiService.addPercentage(
            payload
        );
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        this.appStore.dispatch(
            new AddPercentageAction(
                response.data.currencyAdjustment.addPercentage
            )
        );
        this.loadPercentageByCode(currencyAdjustmentPercentage.currencyAdjustmentCodeId, null, null, null, null, false);
    }

    @Loadable()
    async loadPercentageByCode(
        codeId: number,
        validFrom: Date,
        validTo: Date,
        sortExpression: string,
        filterExpression: string,
        isSimullation: boolean
    ): Promise<void> {
        const payload = {
            order: sortExpression,
            filter: filterExpression,
            codeId: toNumber(codeId),
            validFrom: validFrom ? new Date(validFrom).toISOString() : null,
            validTo: validTo ? new Date(validTo).toISOString() : null,
            isSimullation
        };
        const response = await this.apiService.getPercentages(payload);
        this.appStore.dispatch(new LoadPercentagesAction(response));
    }

    getPercentages(): Observable<CurrencyAdjustmentPercentage[]> {
        return this.appStore.select(
            (state) => state.admin.currencyAdjustmentState.percentageList
        );
    }

    getPercentagesSortingExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.currencyAdjustmentState.codeListSortExpression
        );
    }

    getPercentagesFilterExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.currencyAdjustmentState.codeListFilterExpression
        );
    }

    sortPercentages(sortExpression: string) {
        this.appStore.dispatch(
            new ChangeCodeListSortExpressionAction(sortExpression)
        );
    }

    filterPercentages(filterExpression: string) {
        this.appStore.dispatch(
            new ChangeCodeListFilterExpressionAction(filterExpression)
        );
    }

    clearPercentagesFilterAndSorting() {
        this.sortPercentages(null);
        this.filterPercentages(null);
    }

    @Loadable()
    async loadConditions(
        sortExpression: string,
        filterExpression: string,
        showOnlyValidConditions: boolean
    ): Promise<void> {
        const payload = {
            order: sortExpression,
            filter: filterExpression,
            showOnlyValidConditions
        };
        const response = await this.apiService.getConditions(payload);
        this.appStore.dispatch(new LoadConditionsAction(response));
    }

    getConditionList(): Observable<CurrencyAdjustmentCondition[]> {
        return this.appStore.select(
            (state) => state.admin.currencyAdjustmentState.conditionList
        );
    }

    getConditionsListSortingExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.currencyAdjustmentState.conditionSortExpression
        );
    }

    getConditionsListFilterExpression(): Observable<string> {
        return this.appStore.select(
            (state) =>
                state.admin.currencyAdjustmentState.conditionFilterExpression
        );
    }

    sortConditionsList(sortExpression: string) {
        this.appStore.dispatch(
            new ChangeConditionsSortExpressionAction(sortExpression)
        );
    }

    filterConditionsList(filterExpression: string) {
        this.appStore.dispatch(
            new ChangeConditionsFilterExpressionAction(filterExpression)
        );
    }

    clearConditionsListFilterAndSorting() {
        this.sortConditionsList(null);
        this.filterConditionsList(null);
    }

    @Loadable()
    async loadVariantFamilies(): Promise<void> {
        let list = <VariantFamily[]>(
            this.appState.getValue().admin.currencyAdjustmentState
                .variantFamilyList
        );
        if (list.length === 0) {
            const response = await this.variantService.getVariantFamily();
            this.appStore.dispatch(new LoadVariantFamilyAction(response));
        }
    }

    getVariantFamilies(): Observable<VariantFamily[]> {
        return this.appStore.select(
            (state) => state.admin.currencyAdjustmentState.variantFamilyList
        );
    }

    @Loadable()
    async loadVariantOptions(variantFamilyId: number): Promise<void> {
        const response = await this.variantService.getVariantOptions({
            variantFamilyId,
        });
        this.appStore.dispatch(new LoadVariantOptionAction(response));
    }

    @Loadable()
    async resetVariantOptions(): Promise<void> {
        this.appStore.dispatch(new LoadVariantOptionAction([]));
    }

    getVariantOptions(): Observable<VariantOption[]> {
        return this.appStore.select(
            (state) => state.admin.currencyAdjustmentState.variantOptionsList
        );
    }

    @Loadable()
    async loadProductClass(): Promise<void> {
        let list = <ProductClass[]>(
            this.appState.getValue().admin.currencyAdjustmentState
                .productClassList
        );
        if (list.length === 0) {
            const response = await this.productClassService.getProductClass();
            this.appStore.dispatch(new LoadProductClassAction(response));
        }
    }

    @Loadable()
    async loadMarket(): Promise<void> {
        let list = <Market[]>(
            this.appState.getValue().admin.currencyAdjustmentState.marketList
        );
        if (list.length === 0) {
            const response = await this.marketService.getMarket();
            this.appStore.dispatch(new LoadMarketAction(response));
        }
    }

    getProductClass(): Observable<ProductClass[]> {
        return this.appStore.select(
            (state) => state.admin.currencyAdjustmentState.productClassList
        );
    }

    getMarket(): Observable<Market[]> {
        return this.appStore.select(
            (state) => state.admin.currencyAdjustmentState.marketList
        );
    }

    async addCondition(
        conditionAdd: CurrencyAdjustmentConditionAdd
    ): Promise<any> {
        const marketList = <Market[]>(
            this.appState.getValue().admin.currencyAdjustmentState.marketList
        );
        const productClassList = <ProductClass[]>(
            this.appState.getValue().admin.currencyAdjustmentState
                .productClassList
        );
        const addedConditionsCount = <number>(
            this.appState.getValue().admin.currencyAdjustmentState
                .addedConditions.length
        );
        const codeList = <CurrencyAdjustmentCode[]>(
            this.appState.getValue().admin.currencyAdjustmentState.codeList
        );
        let conditionList = <CurrencyAdjustmentCondition[]>(
            this.appState.getValue().admin.currencyAdjustmentState.conditionList
        );
        const conditionListCount = conditionList.length
            ? conditionList[conditionList.length - 1].id
            : 0;
        const condition: CurrencyAdjustmentCondition = {
            id: addedConditionsCount + conditionListCount + 1,
            marketId: conditionAdd.marketId,
            market: marketList.find((x) => x.id === conditionAdd.marketId),
            productClassId: conditionAdd.productClassId,
            productClass: productClassList.find(
                (x) => x.id === conditionAdd.productClassId
            ),
            materialCurrencyAdjustmentCodeId:
                conditionAdd.materialCurrencyAdjustmentCodeId,
            materialCurrencyAdjustmentCode: codeList.find(
                (x) => x.id === conditionAdd.materialCurrencyAdjustmentCodeId
            ),
            pvCurrencyAdjustmentCodeId: conditionAdd.pvCurrencyAdjustmentCodeId,
            pvCurrencyAdjustmentCode: codeList.find(
                (x) => x.id === conditionAdd.pvCurrencyAdjustmentCodeId
            ),
            variantOptions: [],
            isNew: true,
            validFrom: conditionAdd.validFrom,
            validTo: conditionAdd.validTo,
        };
        this.appStore.dispatch(new AddConditionAction(condition));
    }

    async addConditionVariantOption(
        conditionVariantOptionAdd: CurrencyAdjustmentConditionVariantOptionAdd
    ): Promise<any> {
        const variantOptions = <VariantOption[]>(
            this.appState.getValue().admin.currencyAdjustmentState
                .variantOptionsList
        );
        const variantFamilies = <VariantFamily[]>(
            this.appState.getValue().admin.currencyAdjustmentState
                .variantFamilyList
        );
        const conditions = <CurrencyAdjustmentCondition[]>(
            this.appState.getValue().admin.currencyAdjustmentState.conditionList
        );
        const condition = conditions.filter(
            (x) =>
                x.id === conditionVariantOptionAdd.currencyAdjustmentConditionId
        )[0];
        const variantOption = variantOptions.filter(
            (x) => x.id === conditionVariantOptionAdd.variantOptionId
        )[0];
        const variantFamily = variantFamilies.filter(
            (x) => x.id === variantOption.variantFamilyId
        )[0];
        const conditionVariant: CurrencyAdjustmentConditionVariantOption = {
            id: condition.variantOptions.length + 1,
            currencyAdjustmentCondition: condition,
            currencyAdjustmentConditionId:
                conditionVariantOptionAdd.currencyAdjustmentConditionId,
            variantOptionId: conditionVariantOptionAdd.variantOptionId,
            variantOption: {
                ...variantOption,
                variantFamily: variantFamily,
            },
        };
        this.appStore.dispatch(new AddConditionVariantAction(conditionVariant));
    }

    async removeConditionVariantOption(
        conditionId: number,
        conditionVariantOptionId: number
    ) {
        this.appStore.dispatch(
            new RemoveConditionVariantAction(
                conditionId,
                conditionVariantOptionId
            )
        );
    }

    async saveConditions(): Promise<any> {
        let conditions = <CurrencyAdjustmentCondition[]>(
            this.appState.getValue().admin.currencyAdjustmentState.conditionList
        );
        conditions = conditions.filter((c) => c.isNew === true);
        const conditionAddArr: CurrencyAdjustmentConditionAdd[] = [];

        for (const condition of conditions) {
            const conditionAdd: any = {
                marketId: toNumber(condition.marketId),
                productClassId: toNumber(condition.productClassId),
                materialCurrencyAdjustmentCodeId:
                    toNumber(condition.materialCurrencyAdjustmentCodeId),
                pvCurrencyAdjustmentCodeId:
                    toNumber(condition.pvCurrencyAdjustmentCodeId),
                validFrom: condition.validFrom ? new Date(condition.validFrom).toISOString() : null,
                validTo: condition.validTo ? new Date(condition.validTo).toISOString() : null,
                variantOptions: condition.variantOptions.map<
                    CurrencyAdjustmentConditionVariantOptionAdd
                >((c) => ({
                    variantOptionId: toNumber(c.variantOptionId),
                })),
            };
            conditionAddArr.push(conditionAdd);
        }

        const response = await this.apiService.addCondition(conditionAddArr);
        if (response.errors) {
            const errors = response.errors.map(
                (err: { message: any }) => err.message
            );
            return errors;
        }
        this.appStore.dispatch(
            new SaveConditionAction(
                response.data.currencyAdjustment.addConditions
            )
        );
    }
}
