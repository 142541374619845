import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';
import _, { toNumber } from 'lodash';

import { Loadable } from '../common/loadable';
import { AppState } from '../app.state';
import {
    ChangeProducedComponentListSortExpressionAction,
    ChangeProducedComponentListFilterExpressionAction,
    ChangeProducedComponentListPageAction,
    LoadProducedWidgetAction,
    ChangeComponentSearchFormAction,
    LoadProducedComponentListAction,
    ChangeProducedComponentListTotalCountAction,
    LoadAllocatedWidgetAction,
    LoadAllocatedComponentListAction,
    ChangeAllocatedComponentListTotalCountAction,
    ChangeAllocatedComponentListSortExpressionAction,
    ChangeAllocatedComponentListFilterExpressionAction,
    ChangeAllocatedComponentListPageAction,
    ChangeCurrentUnitColumnsAction,
    LoadInStockComponentListAction,
    ChangeInStockComponentListTotalCountAction,
    LoadInStockWidgetAction,
    ChangeInStockComponentListSortExpressionAction,
    ChangeInStockComponentListFilterExpressionAction,
    ChangeInStockComponentListPageAction
} from './actions';

import { MasterDataState } from '../master-data/state';
import { CalsiumDateFormatterPipe } from 'src/app/shared/calsium-date.pipe';
import { ConsolidationUnitAllocationApiService } from 'src/api/services/consolidation-unit-allocation-api.service';
import { Paging } from '..';
import { ConsolidationUnitAllocationState } from './state';
import { ConsolidationUnitAllocation } from './state-models/consolidation-unit-allocation';
import { AllocationSearchForm } from './state-models/allocation-search-form';
import { AllocationSummary } from './state-models/allocation-summary';
import { AllocationUnitColumns } from './state-models/allocation-unit-columns';
import { ToastrHelperService } from 'src/app/shared/toastr-helper.service';

@Injectable()
export class ConsolidationUnitAllocationStateService {
    private resources = require('../../state/common/resources.json');
    constructor(
        private apiService: ConsolidationUnitAllocationApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>,
        private datePipe: CalsiumDateFormatterPipe) { }

    @Loadable()
    async loadConsolidationUnitAllocationSummary(allocationScaniaUnitId: number, from: string, to: string, forceRefetch: boolean = false): Promise<void> {
        const payload = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            allocationScaniaUnit: toNumber(allocationScaniaUnitId)
        };
        
        const producedSummary = await this.apiService.getConsolidationUnitComponentsSummary('produced', payload, forceRefetch);
        this.appStore.dispatch(new LoadProducedWidgetAction(producedSummary));

        const allocatedSummary = await this.apiService.getConsolidationUnitComponentsSummary('allocated', payload, forceRefetch);
        this.appStore.dispatch(new LoadAllocatedWidgetAction(allocatedSummary));

        const stockSummary = await this.apiService.getConsolidationUnitComponentsSummary('stock', payload, forceRefetch);
        this.appStore.dispatch(new LoadInStockWidgetAction(stockSummary));
    }

    @Loadable()
    async loadConsolidationUnitAllocationList(
        allocationStatus: string,
        allocationScaniaUnitId: number,
        from: string,
        to: string,
        paging: Paging,
        sortExpression: string,
        filterExpression: string,
        columnsConfigration: AllocationUnitColumns[],
        forceRefetch: boolean = false) {
        const payload = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            allocationScaniaUnit: toNumber(allocationScaniaUnitId),
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression,
        };
        const allocationList = await this.apiService.getConsolidationUnitComponentsList(allocationStatus, payload, forceRefetch);
        if (allocationStatus === 'produced') {
            this.appStore.dispatch(new LoadProducedComponentListAction(allocationList.list));
            this.appStore.dispatch(new ChangeProducedComponentListTotalCountAction(allocationList.totalCount, allocationList.errorsCount));
        }
        if (allocationStatus === 'allocated') {
            this.appStore.dispatch(new LoadAllocatedComponentListAction(allocationList.list));
            this.appStore.dispatch(new ChangeAllocatedComponentListTotalCountAction(allocationList.totalCount, allocationList.errorsCount));
        }
        if (allocationStatus === 'stock') {
            this.appStore.dispatch(new LoadInStockComponentListAction(allocationList.list));
            this.appStore.dispatch(new ChangeInStockComponentListTotalCountAction(allocationList.totalCount, allocationList.errorsCount));
        }

        const currentColumnsConfig = columnsConfigration.find(x => x.allocationUnits.indexOf(allocationScaniaUnitId) >= 0);
        this.appStore.dispatch(new ChangeCurrentUnitColumnsAction(currentColumnsConfig.columns));
    }

    async refetchList(allocationScaniaUnitId: number, from: string, to: string, columnsConfigration: AllocationUnitColumns[], allocationStatus: string): Promise<void> {
        await this.loadConsolidationUnitAllocationSummary(allocationScaniaUnitId, from, to, true);

        const state = <ConsolidationUnitAllocationState>this.appState.getValue().consolidationUnitAllocation;
        await this.loadConsolidationUnitAllocationList(allocationStatus, allocationScaniaUnitId, from, to, state.producedPaging, state.producedSortExpression, state.producedFilterExpression, columnsConfigration, true);
    }

    getAllocationUnitColumnsConfigration(): Observable<AllocationUnitColumns[]> {
        return this.appStore.select(state => state.consolidationUnitAllocation.allocationUnitColumnsConfigration);
    }

    getList(type: string): Observable<ConsolidationUnitAllocation[]> {
        return this.appStore.select(state => state.consolidationUnitAllocation[`${type}ComponentList`]);
    }

    getSortingExpression(type: string): Observable<string> {
        return this.appStore.select(state => state.consolidationUnitAllocation[`${type}SortExpression`]);
    }

    search(componentSearchForm: AllocationSearchForm) {
        this.appStore.dispatch(new ChangeComponentSearchFormAction(componentSearchForm));
    }

    getComponentSearchForm(): Observable<AllocationSearchForm> {
        return this.appStore.select(state => state.consolidationUnitAllocation.searchForm);
    }

    sort(type: string, sortExpression: string) {
        if (type === 'produced') {
            this.appStore.dispatch(new ChangeProducedComponentListSortExpressionAction(sortExpression));
        }
        if (type === 'allocated') {
            this.appStore.dispatch(new ChangeAllocatedComponentListSortExpressionAction(sortExpression));
        }
        if (type === 'stock') {
            this.appStore.dispatch(new ChangeInStockComponentListSortExpressionAction(sortExpression));
        }
    }

    getFilterExpression(type: string): Observable<string> {
        return this.appStore.select(state => state.consolidationUnitAllocation[`${type}FilterExpression`]);
    }

    filter(type: string, filterExpression: string) {
        if (type === 'produced') {
            this.appStore.dispatch(new ChangeProducedComponentListFilterExpressionAction(filterExpression));
        }
        if (type === 'allocated') {
            this.appStore.dispatch(new ChangeAllocatedComponentListFilterExpressionAction(filterExpression));
        }
        if (type === 'stock') {
            this.appStore.dispatch(new ChangeInStockComponentListFilterExpressionAction(filterExpression));
        }
    }

    getPaging(type: string): Observable<Paging> {
        return this.appStore.select(state => state.consolidationUnitAllocation[`${type}Paging`]);
    }

    goToPage(type: string, page: number) {
        if (type === 'produced') {
            this.appStore.dispatch(new ChangeProducedComponentListPageAction(page));
        }
        if (type === 'allocated') {
            this.appStore.dispatch(new ChangeAllocatedComponentListPageAction(page));
        }
        if (type === 'stock') {
            this.appStore.dispatch(new ChangeInStockComponentListPageAction(page));
        }
    }

    getTotalCount(type: string): Observable<number> {
        return this.appStore.select(state => state.consolidationUnitAllocation[`${type}TotalCount`]);
    }

    getErrorCount(type: string): Observable<number> {
        return this.appStore.select(state => state.consolidationUnitAllocation[`${type}ErrorsCount`]);
    }

    getConsolidationUnitSummary(type: string): Observable<AllocationSummary> {
        var a = this.appStore.select(state => state.consolidationUnitAllocation[`${type}Widget`]);
        return a;
    }

    getCurrentUnitColumns(): Observable<string[]> {
        return this.appStore.select(state => state.consolidationUnitAllocation.currentUnitColumns);
    }

    clearFilterAndSorting(type: string) {
        if (type === 'produced') {
            [
                new ChangeProducedComponentListSortExpressionAction(null),
                new ChangeProducedComponentListFilterExpressionAction(null)
            ].forEach(action => this.appStore.dispatch(action));
        }
        if (type === 'allocated') {
            [
                new ChangeAllocatedComponentListSortExpressionAction(null),
                new ChangeAllocatedComponentListFilterExpressionAction(null)
            ].forEach(action => this.appStore.dispatch(action));
        }
        if (type === 'stock') {
            [
                new ChangeInStockComponentListSortExpressionAction(null),
                new ChangeInStockComponentListFilterExpressionAction(null)
            ].forEach(action => this.appStore.dispatch(action));
        }
    }

    exportAllocationData(allocationStatus: string): Promise<any> {
        const state = <ConsolidationUnitAllocationState>this.appState.getValue().consolidationUnitAllocation;
        const searchForm = state.searchForm;
        const masterData = <MasterDataState>this.appState.getValue().masterData;
        const scaniaUnit = masterData.scaniaUnits.find(su => su.id === searchForm.selectedScaniaUnitId);
        const payload = {
            from: new Date(searchForm.fromDate).toISOString(),
            to: new Date(searchForm.toDate).toISOString(),
            allocationScaniaUnit: toNumber(searchForm.selectedScaniaUnitId)
        };
        const fileName = `Allocation_${scaniaUnit.name}_${payload.from}_${payload.to}`;

        return this.apiService.exportConsolidationUnitAllocationList(allocationStatus, scaniaUnit.name, payload, fileName);
    }

    @Loadable()
    async allocate(allocationScaniaUnitId: number, from: string, to: string, filterExpression: string, selectAll: boolean, includedItems: number[], excludedItems: number[], columnsConfigration: AllocationUnitColumns[], preview: boolean, allocationStatus: string): Promise<any> {
        const payload = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            selectAll,
            includedItems,
            excludedItems,
            allocationScaniaUnit: toNumber(allocationScaniaUnitId),
            filter: filterExpression,
            preview
        };
        const response = await this.apiService.allocate(payload);
        if (response.errors) {
            const errors = response.errors.map((err: { message: any; }) => err.message);
            return { errors };
        }
        await this.refetchList(allocationScaniaUnitId, from, to, columnsConfigration, allocationStatus);
        const allocateTotal = _.get(response, 'data.consolidationUnitAllocation.allocate.length', 0);
        return { success: allocateTotal };
    }

    resetToDefaults() {
        const summary = { totalValue: 0, totalAmount: 0 };

        this.appStore.dispatch(new LoadProducedWidgetAction(summary));
        this.appStore.dispatch(new LoadAllocatedWidgetAction(summary));
        this.appStore.dispatch(new LoadInStockWidgetAction(summary));

        this.appStore.dispatch(new LoadProducedComponentListAction([]));
        this.appStore.dispatch(new ChangeProducedComponentListTotalCountAction(0, 0));

        this.appStore.dispatch(new LoadAllocatedComponentListAction([]));
        this.appStore.dispatch(new ChangeAllocatedComponentListTotalCountAction(0, 0));

        this.appStore.dispatch(new LoadInStockComponentListAction([]));
        this.appStore.dispatch(new ChangeInStockComponentListTotalCountAction(0, 0));
    }
}
