import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { result, toNumber } from 'lodash';
import { map, lastValueFrom } from 'rxjs';
import { MarkupCondition } from 'src/state/administraion/markup-condition-material/state-models/markup-condition';
import { MarkupConditionMaterial, MarkupConditionMaterialAdd, MarkupConditionMaterialUpdate } from 'src/state/administraion/markup-condition-material/state-models/markup-condition-material';

@Injectable({
    providedIn: "root",
})
export class MarkupConditionMaterialApiService {
    constructor(private apollo: Apollo) { }

    async AddMarkupConditionMaterial(
        input: MarkupConditionMaterialAdd
    ): Promise<any> {

        const payload = {
            markupConditionId: toNumber(input.markupConditionId),
            materialId: toNumber(input.materialId),
            validFrom: input.validFrom ? new Date(input.validFrom).toISOString() : null,
            validTo: input.validTo ? new Date(input.validTo).toISOString() : null,
        };

        const mutation = `
        mutation addMarkupConditionMaterial($payload:MarkupConditionMaterialInput!){
            markupConditionMaterial{
                addMarkupConditionMaterial(payload: $payload)
                {
                    id
                    markupConditionId
                    materialId
                    validFrom
                    validTo
                }
            }
        }
        `;
        const result$ = this.apollo
            .mutate({
                mutation: gql(mutation),
                variables: { payload },
            });


        return lastValueFrom(result$);

    }

    getMarkupCondition(variables?): Promise<MarkupCondition[]> {
        const result$ = this.apollo
            .query<MarkupCondition[]>({
                variables,
                fetchPolicy: "no-cache",
                query: gql`
                    query markupConditionQuery(
                        $order: String
                        $filter: String
                    ) {
                        markupCondition {
                            markupCondition(order: $order, filter: $filter) {
                                id
                                markupConditionCode
                            }
                        }
                    }
                `,
            })
            .pipe<MarkupCondition[]>(

                map((result: any) => {
                    return result?.data?.markupCondition?.markupCondition;
                })
            )

        return lastValueFrom(result$);
    }

    getMarkupConditionMaterials(
        variables?
    ): Promise<MarkupConditionMaterial[]> {
        const result$ = this.apollo
            .query<MarkupConditionMaterial[]>({
                variables,
                fetchPolicy: "no-cache",
                query: gql`
                    query markupConditionQuery(
                        $order: String
                        $filter: String
                    ) {
                        markupCondition {
                            markupConditionMaterials(
                                order: $order
                                filter: $filter
                            ) {
                                id
                                markupConditionId
                                materialId
                                validFrom
                                validTo
                                markupCondition {
                                    id
                                    markupConditionCode
                                }
                                material {
                                    id
                                    materialNumber
                                }
                            }
                        }
                    }
                `,
            })
            .pipe<MarkupConditionMaterial[]>(
                map((result: any) => {
                    return result?.data?.markupCondition?.markupConditionMaterials;
                })
            )

        return lastValueFrom(result$);
    }

    async updateMarkupConditionMaterial(
        input: MarkupConditionMaterialUpdate
    ): Promise<any> {

        const payload = {
            id: toNumber(input.id),
            validFrom: input.validFrom ? new Date(input.validFrom).toISOString() : null,
            validTo: input.validTo ? new Date(input.validTo).toISOString() : null,
        };

        const mutation = `
           mutation updateMarkupConditionMaterial($payload: MarkupConditionMaterialUpdate!) {
             markupConditionMaterial {
              updateMarkupConditionMaterial (payload: $payload) {
                 id
                 validFrom
                 validTo
               }
             }
           }`;

        const result$ = this.apollo
            .mutate({
                mutation: gql(mutation),
                variables: { payload },
            });

        return lastValueFrom(result$);
    }
}
