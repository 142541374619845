import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { toNumber } from 'lodash';
import { ManualInvoicing } from 'src/app/administration/pages/manual-invoicing/manual-invoicing-model';
@Injectable({
  providedIn: 'root'
})
export class ManualInvoicingApiService {
  constructor(private apollo: Apollo) { }

  verify(input: ManualInvoicing[]): Promise<any> {

    let payload = input.map((item) => {
      return {
        chassisNumber: item.chassisNumber,
        countryCode: item.countryCode,
        invoiceAmount: toNumber(item.invoiceAmount),
        responsibilityCode: item.responsibilityCode,
        invoiceDate: item.invoiceDate ? new Date(item.invoiceDate).toISOString() : null
      }
    })

    const mutation = `
        mutation ($payload: [ManualInvoicingInput]!) {
            manualInvoicing {
              verify(payload: $payload){
                chassisNumber
                serialNumber
                countryCode
                invoiceAmount
                invoiceDate
                responsibilityCode
              }
          }
        }`;

    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    }).toPromise();
  }

  async confirm(input: ManualInvoicing[]): Promise<any> {

    let payload = input.map((item) => {
      return {
        chassisNumber: item.chassisNumber,
        countryCode: item.countryCode,
        invoiceAmount: toNumber(item.invoiceAmount),
        responsibilityCode: item.responsibilityCode,
        invoiceDate: item.invoiceDate ? new Date(item.invoiceDate).toISOString() : null
      }
    })

    const mutation = `
        mutation ($payload: [ManualInvoicingInput]!) {
            manualInvoicing {
              confirm(payload: $payload)
          }
        }`;

    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    }).toPromise();
  }
}
