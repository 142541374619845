import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { toNumber } from "lodash";
import { map, lastValueFrom } from "rxjs";
import { ComponentType, ComponentUnit, ProductClass } from "src/state";
import { ProductClassComponentUnit, ProductClassComponentUnitAdd } from "src/state/administraion/product-class-component-unit/state-model/product-class-component-unit";


@Injectable({
    providedIn: "root",
})
export class ProductClassComponentUnitApiService {
    constructor(private apollo: Apollo) { }

    async AddProductClassComponentUnit(
        input: ProductClassComponentUnitAdd
    ): Promise<any> {

        const payload = {
            componentUnit: {
                code: toNumber(input.componentUnit.code),
                description: input.componentUnit.description
            },
            productClassId: toNumber(input.productClassId),
            componentTypeId: toNumber(input.componentTypeId),
        };

        const mutation = `
        mutation addProductClassComponentUnit($payload: ProductClassComponentUnitInput!){
            productClassComponentUnit{
                addProductClassComponentUnit(payload: $payload)
                {
                    id
                    productClassId
                    componentTypeId
                    componentUnit {
                        id
                        code
                        description
                    }
                    componentType {
                        id
                        name
                        description
                        code
                    }
                    productClass {
                        id
                        variantOption {
                            description
                        }
                    }       
                }
            }
        }
        `;
        return this.apollo
            .mutate({
                mutation: gql(mutation),
                variables: { payload },
            })
            .toPromise();
    }

    getComponentType(variables?): Promise<ComponentType[]> {
        const result$ = this.apollo
            .query<ComponentType[]>({
                variables,
                fetchPolicy: "no-cache",
                query: gql`
                    query componentTypeQuery(
                        $order: String
                        $filter: String
                    ) {
                        productClassComponentUnit {
                            componentType(order: $order, filter: $filter) {
                                id
                                name
                                description
                                code
                            }
                        }
                    }
                `,
            })
            .pipe<ComponentType[]>(

                map((result: any) => {
                    return result?.data?.productClassComponentUnit?.componentType;
                }
                ));

        return lastValueFrom(result$);
    }

    getProductClassComponentUnits(
        variables?
    ): Promise<any> {
        const result$ = this.apollo
            .query<ProductClassComponentUnit[]>({
                variables,
                fetchPolicy: "no-cache",
                query: gql`
                    query productClassComponentUnitQuery($order:String, $filter:String, $paging:Paging) 
                    {
                        productClassComponentUnit 
                        {
                            productClassComponentUnits(
                                order: $order
                                filter: $filter
                                paging: $paging
                            )
                            {
                                list
                                {                                    
                                    id
                                    productClassId
                                    componentTypeId
                                    componentUnit {
                                        code
                                        description
                                    }
                                    componentType {
                                        name
                                        description
                                        code
                                    }
                                    productClass {
                                        variantOption {
                                            description
                                        }
                                    }                                    
                                }
                                totalCount        
                                errorsCount: extraHeaderInfo1
                            }
                        }
                    }
                `,
            })
            .pipe<ProductClassComponentUnit[]>(

                map((result: any) => {
                    return result?.data?.productClassComponentUnit?.productClassComponentUnits;
                }
                ));

        return lastValueFrom(result$);
    }
}    