import { Component, OnInit } from '@angular/core';
import { CommonStateService } from 'src/state/common/service';
import { CalsiumListColumnItem, CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CalsiumDateFormatter } from 'src/app/shared/calsium-date-formatter';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Material, Paging } from 'src/state';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MissingSIMMaterial } from 'src/state/missing-sim-material/state-models/missing-sim-material';
import { MissingSIMMaterialListStateService } from 'src/state/missing-sim-material/service';
import { MaterialSearchForm } from 'src/state/missing-sim-material/state-models/missing-material-search-form';

@Component({
  selector: 'cal-missing-sim-materials',
  templateUrl: './missing-sim-materials.component.html',
  styleUrls: ['./missing-sim-materials.component.css']
})
export class MissingSIMMaterialsComponent implements OnInit {

  resources: any = this.commonService.getResources();
  searchComponentsForm: UntypedFormGroup;
  materialSearchForm: Observable<MaterialSearchForm>
  listConfig: CalsiumListConfig;

  dataList: Observable<MissingSIMMaterial[]>;
  paging: Observable<Paging>;
  totalCount: Observable<number>;
  errorCount: Observable<number>;
  subscriptions = new Subscription();
  isLoading: Observable<boolean>;
  sortingExpression: Observable<string>;
  filterExpression: Observable<string>;
  constructor(
    private commonService: CommonStateService,
    private missingSIMMaterialService: MissingSIMMaterialListStateService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.isLoading = this.commonService.getLoading();
    this.sortingExpression = this.missingSIMMaterialService.getSortingExpression();
    this.filterExpression = this.missingSIMMaterialService.getFilterExpression();
    this.totalCount = this.missingSIMMaterialService.getTotalCount();
    this.paging = this.missingSIMMaterialService.getPaging();
    this.dataList = this.missingSIMMaterialService.getList();
    this.materialSearchForm = this.missingSIMMaterialService.getMaterialSearchForm();

    let pageInitialLoad = true;
    this.subscriptions.add(
      combineLatest(this.materialSearchForm, this.paging, this.sortingExpression, this.filterExpression).pipe(
        debounceTime(0),
        switchMap(([searchForm, paging, sortingExpression, filterExpression]) => {
          if (searchForm) {
            this.fillSearchForm(searchForm.fromDate, searchForm.toDate);
          }

          if (!pageInitialLoad) {
            return this.missingSIMMaterialService.loadMissingSIMMaterialList(searchForm,
              paging,
              sortingExpression,
              filterExpression,
              true
            );
          }
          pageInitialLoad = false;
          return new Promise<void>(null);
        })
      ).subscribe()
    );
    this.fillSearchForm();
    this.buildList();
  }

  ngOnDestroy() { this.subscriptions.unsubscribe(); }

  fillSearchForm(fromDate: Date = null, toDate: Date = null) {
    this.searchComponentsForm = this.formBuilder.group({
      startDate: [CalsiumDateFormatter.getDateOrDefault(fromDate, true), []],
      endDate: [CalsiumDateFormatter.getDateOrDefault(toDate, false), []],
    });
  }
  buildList() {
    this.listConfig = new CalsiumListConfig();
    this.listConfig.ListCaption = this.resources.List;
    this.listConfig.ShowFilterRow = true;
    this.listConfig.ParentComponent = this;
    this.listConfig.ShowExportToExcel = true;
    let column;
    column = this.listConfig.AddListColumn(ColumnDataType.number, 'materialNumber', this.resources.MaterialNumber);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'materialName', this.resources.MaterialName);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'componentType', this.resources.ComponentType);
    column.AllowFilter = true;
    column.Sortable = true;
  }

  onPaging(page: number) {
    this.missingSIMMaterialService.goToPage(page);
  }

  onSort(sortExpression: string) {
    this.missingSIMMaterialService.sort(sortExpression);
  }

  onFilter(filterExpression: string) {
    this.missingSIMMaterialService.filter(filterExpression);
  }

  onClearFilterAndSorting() {
    this.missingSIMMaterialService.clearFilterAndSorting();
  }

  onSearch() {
    if (this.searchComponentsForm.valid) {
      const fromDate = this.searchComponentsForm.value.startDate;
      const toDate = this.searchComponentsForm.value.endDate;
      const param: MaterialSearchForm = {
        fromDate,
        toDate
      };
      this.missingSIMMaterialService.changeMaterialSearchForm(param);
    }
  }

  onExportToExcel() {
    const fromDate = this.searchComponentsForm.value.startDate;
    const toDate = this.searchComponentsForm.value.endDate;
    const payload = {
      from: new Date(fromDate).toISOString(),
      to: new Date(toDate).toISOString()
    }
    const fileName = `MissingSimMaterials_${fromDate}_${toDate}`;
    return this.missingSIMMaterialService.exportMissingMaterialExcel(fileName, payload);
  }
}
