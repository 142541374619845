import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { toNumber } from 'lodash';
import { map, lastValueFrom } from 'rxjs';
import { Market, ProductClass } from 'src/state';
import { MarkupCondition } from 'src/state/administraion/markup-condition-material/state-models/markup-condition';
import { MarkupPercentage, MarkupPercentageAdd } from 'src/state/administraion/markup-percentage/state-models/markup-percentage';

@Injectable({
    providedIn: "root",
})
export class MarkupPercentageApiService {
    constructor(private apollo: Apollo) { }

    async AddMarkupPercentage(
        input: MarkupPercentageAdd
    ): Promise<any> {


        const payload = {
            productClassId: toNumber(input.productClassId),
            markupConditionId: toNumber(input.markupConditionId),
            marketId: toNumber(input.marketId),
            percentage: toNumber(input.percentage),
            validFrom: input.validFrom ? new Date(input.validFrom).toISOString() : null,
            validTo: input.validTo ? new Date(input.validTo).toISOString() : null
        };

        const mutation = `
        mutation addMarkupPercentage($payload: MarkupPercentageInput!){
            markupPercentage{
                addMarkupPercentage(payload: $payload)
                {
                    id
                    productClassId
                    markupConditionId
                    marketId
                    percentage
                    validFrom
                    validTo
                    markupCondition {
                        id
                        markupConditionCode
                    }
                    market {
                        id
                        name
                    }
                    productClass {
                        id
                        variantOption {
                            description
                        }
                    }       
                }
            }
        }
        `;
        return this.apollo
            .mutate({
                mutation: gql(mutation),
                variables: { payload },
            })
            .toPromise();
    }

    getProductClass(variables?): Promise<ProductClass[]> {
        const result$ = this.apollo
            .query<ProductClass[]>({
                variables,
                fetchPolicy: "no-cache",
                query: gql`
                    query productClassQuery(
                        $order: String
                        $filter: String
                    ) {
                        markupPercentage {
                            productClass(order: $order, filter: $filter) {
                                id
                                variantOption {
                                    description
                                }
                            }
                        }
                    }
                `,
            })
            .pipe<ProductClass[]>(

                map((result: any) => {
                    return result?.data?.markupPercentage?.productClass;
                })
            )

        return lastValueFrom(result$);
    }
    getMarkupCondition(variables?): Promise<MarkupCondition[]> {
        const result$ = this.apollo
            .query<MarkupCondition[]>({
                variables,
                fetchPolicy: "no-cache",
                query: gql`
                    query markupConditionQuery(
                        $order: String
                        $filter: String
                    ) {
                        markupPercentage {
                            markupCondition(order: $order, filter: $filter) {
                                id
                                markupConditionCode
                            }
                        }
                    }
                `,
            })
            .pipe<MarkupCondition[]>(

                map((result: any) => {
                    return result?.data?.markupPercentage?.markupCondition;
                })
            )

        return lastValueFrom(result$);
    }

    getMarket(variables?): Promise<Market[]> {
        const result$ = this.apollo
            .query<Market[]>({
                variables,
                fetchPolicy: "no-cache",
                query: gql`
                    query marketQuery(
                        $order: String
                        $filter: String
                    ) {
                        markupPercentage {
                            market(order: $order, filter: $filter) {
                                id
                                name
                            }
                        }
                    }
                `,
            })
            .pipe<Market[]>(

                map((result: any) => {
                    return result?.data?.markupPercentage?.market;
                })
            )

        return lastValueFrom(result$);
    }

    getMarkupPercentages(
        variables?
    ): Promise<any> {
        const result$ = this.apollo
            .query<MarkupPercentage[]>({
                variables,
                fetchPolicy: "no-cache",
                query: gql`
                    query markupPercentageQuery($order:String, $filter:String, $paging:Paging,$showOnlyValidPercentages: Boolean) 
                    {
                        markupPercentage 
                        {
                            markupPercentages(
                                order: $order
                                filter: $filter
                                paging: $paging
                                showOnlyValidPercentages :$showOnlyValidPercentages
                            )
                            {
                                list
                                {                                    
                                    id
                                    productClassId
                                    markupConditionId
                                    marketId
                                    percentage
                                    validFrom
                                    validTo
                                    markupCondition {
                                        id
                                        markupConditionCode
                                    }
                                    market {
                                        id
                                        name
                                    }
                                    productClass {
                                        id
                                        variantOption {
                                            description
                                        }
                                    }                                    
                                }
                                totalCount        
                                errorsCount: extraHeaderInfo1
                            }
                        }
                    }
                `,
            })
            .pipe<MarkupPercentage[]>(

                map((result: any) => {
                    return result?.data?.markupPercentage?.markupPercentages;
                })
            )

        return lastValueFrom(result$);

    }
}
