import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription, Observable, combineLatest, of } from "rxjs";

import { CommonStateService } from "src/state";
import { debounceTime, switchMap } from "rxjs/operators";
import {
    CalsiumListConfig,
    ColumnDataType,
} from "src/app/calsiumlist/calsiumlist.component.model";
import { CurrencyAdjustmentStateService } from "src/state/administraion/currency-adjustment/service";
import {
    CurrencyAdjustmentCode,
    CurrencyAdjustmentCodeAdd,
} from "src/state/administraion/currency-adjustment/state-models/currency-adjustment-code";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/errorhandler/error-handler.service";
import { ErrorTypeEnum } from "src/app/shared/enums.model";
import { ComponentDetailsStateService } from "src/state/componentDetails/service";
import { toNumber } from "lodash";

@Component({
    selector: "cal-currency-adjustment-code",
    templateUrl: "./currency-adjustment-code.component.html",
    styleUrls: ["./currency-adjustment-code.component.css"],
})
export class CurrencyAdjustmentCodeComponent implements OnInit, OnDestroy {
    resources: any = this.commonService.getResources();
    subscriptions = new Subscription();
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;
    dataList: Observable<CurrencyAdjustmentCode[]>;
    isLoading: Observable<boolean>;
    showOnlyValidCodes: Observable<boolean>;
    listConfig: CalsiumListConfig;

    constructor(
        private apiService: ComponentDetailsStateService,
        private router: Router,
        private currencyAdjustmentService: CurrencyAdjustmentStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService
    ) {}

    ngOnInit(): void {
        this.isLoading = this.commonService.getLoading();
        this.sortingExpression = this.currencyAdjustmentService.getCodeListSortingExpression();
        this.filterExpression = this.currencyAdjustmentService.getCodeListFilterExpression();
        this.dataList = this.currencyAdjustmentService.getCodeList();
        this.showOnlyValidCodes = this.currencyAdjustmentService.getShowOnlyValidCodes();



        this.subscriptions.add(
            combineLatest(
                this.sortingExpression,
                this.filterExpression,
                this.showOnlyValidCodes
            )
                .pipe(
                    debounceTime(0),
                    switchMap(
                        ([
                            sortingExpression,
                            filterExpression,
                            showOnlyValidCodes,
                        ]) => {
                            return this.currencyAdjustmentService.loadCode(
                                sortingExpression,
                                filterExpression,
                                showOnlyValidCodes
                            );
                        }
                    )
                )
                .subscribe()
        );
        this.buildList();
    }

    isEditable(condition): boolean {
        let isEditable = false;

        if (condition.validTo === null) {
            isEditable = true;
        }

        return isEditable;
    }
    saveEditedItem(formGroup, selectedDate) {
                    // call the edit/save api
                    const payload = {
                        id:selectedDate.id,
                        code: selectedDate.code,
                        validFrom:selectedDate.validFrom,
                        validTo: formGroup.controls.validTo.value
                    };
                this.currencyAdjustmentService.updateCode(payload).then((errors) => {
                    if (errors && errors.length) {
                        let errorMessage = "";
                        for (const error of errors) {
                            switch (error) {
                                case "INVALID_DATE":
                                    errorMessage += this.resources.ToDateGreaterThanFromDate;
                                    break;
                            }
                        }
                        this.errHandler.emitError(
                            this.resources.ToDateShouldBeTomorrowOrLater,
                            this.resources.Error,
                            ErrorTypeEnum.Error
                        );
                    } else {
                        this.currencyAdjustmentService.filterCodeList(payload.validTo);
                        this.errHandler.emitError(
                            this.resources.UpdateSuccessfully,
                            this.resources.Success,
                            ErrorTypeEnum.Success
                        );
                    }                    
                });           
        return of(true);
    }

    buildList() {
        this.listConfig = new CalsiumListConfig();
        this.listConfig.ListCaption = this.resources.CurrencyAdjustmentCodeList;
        this.listConfig.ShowFilterRow = true;
        this.listConfig.ParentComponent = this;
        this.listConfig.ShowExportToExcel = true;
        this.listConfig.AllowEdit = true;
        this.listConfig.isRowEditable = this.isEditable;
        this.listConfig.EditItemMethod = this.saveEditedItem;
        this.listConfig.ShowExportToExcel = false;
        this.listConfig.ActionsButttons.push({
            IconCssClass: "fas fa-percentage black",
            Title: "Percentages",
            OnClick: this.onPercentageClick,
        });

        let column;
        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "id",
            this.resources.Id
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.string,
            "code",
            this.resources.Code
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "validFrom",
            this.resources.From
        );
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.listConfig.AddListColumn(
            ColumnDataType.date,
            "validTo",
            this.resources.To
        );
        column.AllowFilter = true;
        column.Sortable = true;
        column.AllowEdit = true;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onSort(sortExpression: string) {
        this.currencyAdjustmentService.sortCodeList(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.currencyAdjustmentService.filterCodeList(filterExpression);
    }

    onClearFilterAndSorting() {
        this.currencyAdjustmentService.clearCodeListFilterAndSorting();
    }

    onPercentageClick(rowdata: CurrencyAdjustmentCode) {
        this.router.navigate(["/admin/currency-adjustment-code/" + rowdata.id]);
    }

    async onCodeDelete(rowData: CurrencyAdjustmentCode) {
        this.currencyAdjustmentService.deleteCode(toNumber(rowData.id)).then((errors) => {
            if (errors && errors.length) {
                let errorMessage = "";
                for (let error of errors) {
                    const errorType = error
                        .split("\n")[0]
                        .replace("GraphQL.ExecutionError:", "")
                        .trim();
                    switch (errorType) {
                        case "IN_USE":
                            errorMessage += this.resources.CodeInUse;
                            break;
                        default:
                            errorMessage += this.resources.ErrorOccured;
                            break;
                    }
                }
                this.errHandler.emitError(
                    errorMessage,
                    this.resources.Error,
                    ErrorTypeEnum.Error
                );
            } else {
                this.errHandler.emitError(
                    this.resources.DeletedSuccessfully,
                    this.resources.Success,
                    ErrorTypeEnum.Success
                );
            }
        });
    }

    onShowOnlyValidCodes(showOnlyValidCodes: boolean) {
        this.currencyAdjustmentService.showOnlyValidCodes(showOnlyValidCodes);
    }


}
