import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { ConsumingPlanningAreaMappingStateService } from 'src/state/administraion/consuming-planning-area-mapping/service';
import { ConsumingPlanningAreaMapping } from 'src/state/administraion/consuming-planning-area-mapping/state-models/consuming-planning-area-mapping';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { toNumber } from 'lodash';

@Component({
    selector: 'cal-cpa-add-mapping',
    templateUrl: './cpa-mappings-add-cpa.component.html',
    styleUrls: ['./cpa-mappings-add-cpa.component.css']
})

export class CpaAddMappingComponent implements OnInit, OnDestroy {
    @Input() resources: any;
    @Input() material$: Observable<ConsumingPlanningAreaMapping>;
    material: ConsumingPlanningAreaMapping;
    subscription = new Subscription();
    addCPAMappingForm: UntypedFormGroup;
    isManualSelected = false;
    minFromDate: Date;

    constructor(private consumingPlanningAreaMappingService: ConsumingPlanningAreaMappingStateService,
        private errHandler: ErrorHandlerService,
        private formBuilder: UntypedFormBuilder
    ) { }

    onSave() {
        if (this.isValidForm()) {
            const payload: any = {
                id: toNumber(0),
                from: this.addCPAMappingForm.value.cpaMappingFrom,
                to: this.addCPAMappingForm.value.cpaMappingTo,
                validFrom: new Date(this.addCPAMappingForm.value.cpaMappingValidFrom).toISOString() ||  new Date(new Date().getFullYear() - 1, 0, 1).toISOString(),
                validTo: new Date(this.addCPAMappingForm.value.cpaMappingValidTo).toISOString()
            };
            this.consumingPlanningAreaMappingService.addConsumingPlanningAreaMapping(payload).then((errors) => {
                if (errors && errors.length) {
                    let errorMessage = '';
                    for (const error of errors) {
                        const errorType = error.split('\n')[0].replace('GraphQL.ExecutionError:', '').trim();
                        switch (errorType) {
                            case 'ALREADY_EXISTING_CPA_MAPPING':
                                errorMessage += this.resources.AlreadyExistingConsumingPlanningAreaMapping;
                                break;
                            case 'ALREADY_EXISTING_CPA_MAPPING_VALID_FROM':
                                errorMessage += this.resources.AlreadyExistingConsumingPlanningAreaMappingValidFrom;
                                break;
                            default:
                                errorMessage += this.resources.InvalidConsumingPlanningAreaMapping;
                                break;
                        }
                    }
                    this.errHandler.emitError(errorMessage, this.resources.Error, ErrorTypeEnum.Error);
                } else {
                    this.errHandler.emitError(this.resources.ConsumingPlanningAreaMappingAddedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
                    this.addCPAMappingForm.reset();
                }
            });
        }
    }
    isValidForm(): boolean {
        let valid = true;
        if (!this.addCPAMappingForm.valid) {
            if (this.addCPAMappingForm.get('cpaMappingValidFrom').errors != null) {
                if (this.addCPAMappingForm.get('cpaMappingValidFrom').errors.required) {
                    this.errHandler.emitError(this.resources.SetFromDate, this.resources.InvalidDates, ErrorTypeEnum.Error);
                    valid = false;
                }
            }
            if (this.addCPAMappingForm.get('cpaMappingFrom').errors != null) {
                if (this.addCPAMappingForm.get('cpaMappingFrom').errors.required) {
                    this.errHandler.emitError(this.resources.SetFromConsumingPlanningArea, this.resources.InvalidConsumingPlanningArea, ErrorTypeEnum.Error);
                    valid = false;
                }
            }
            if (this.addCPAMappingForm.get('cpaMappingTo').errors != null) {
                if (this.addCPAMappingForm.get('cpaMappingTo').errors.required) {
                    this.errHandler.emitError(this.resources.SetToConsumingPlanningArea, this.resources.InvalidConsumingPlanningArea, ErrorTypeEnum.Error);
                    valid = false;
                }
            }
        }
        if (this.addCPAMappingForm.value.cpaMappingValidTo < this.addCPAMappingForm.value.cpaMappingValidFrom) {
            this.errHandler.emitError(this.resources.ToDateGreaterThanFromDate, this.resources.InvalidDates, ErrorTypeEnum.Error);
            valid = false;
        }

        return valid;
    }
    ngOnInit() {
        this.buildForm();
        this.minFromDate = new Date();
    }

    buildForm() {
        this.addCPAMappingForm = this.formBuilder.group({
            cpaMappingFrom: ['', <any>Validators.required],
            cpaMappingTo: ['', <any>Validators.required],
            cpaMappingValidFrom: [<Date>null, <any>Validators.required],
            cpaMappingValidTo: [<Date>null, []],
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
