import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { CommonStateService } from '../../../../state';
import { ComponentIndividual } from '../../../../state/flow/state-models/component-individual';
import { ComponentDetailsStateService } from '../../../../state/componentDetails/service';
import _ from 'lodash';

@Component({
    selector: 'cal-component-details',
    templateUrl: './component-details.component.html',
    styleUrls: ['./component-details.component.css']
})
export class ComponentDetailsComponent implements OnInit, OnDestroy {

    isLoading: Observable<boolean>;
    resources: any = this.commonService.getResources();
    componentIndividual$: Observable<ComponentIndividual>;

    totalMaterialCount: number;
    totalSpecificationCount: number;

    subscriptions = new Subscription();
    componentId: number;
    productIndividualId: number;

    constructor(private route: ActivatedRoute,
        private apiService: ComponentDetailsStateService,
        private commonService: CommonStateService) {
    }

    ngOnInit() {
        this.isLoading = this.commonService.getLoading();
        this.componentIndividual$ = this.apiService.getComponentDetails();

        this.subscriptions.add(
            this.route.params.subscribe(params => this.apiService.loadComponentDetailsData(Number(params.id)))
        );
    }

    ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
