import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subscription, combineLatest } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { CalsiumListConfig, ColumnDataType } from '../../../../calsiumlist/calsiumlist.component.model';

import { CommonStateService, Paging } from 'src/state';
import { ConsumingPlanningAreaMappingStateService } from 'src/state/administraion/consuming-planning-area-mapping/service';
import { ConsumingPlanningAreaMapping } from 'src/state/administraion/consuming-planning-area-mapping/state-models/consuming-planning-area-mapping';
import { ErrorHandlerService } from 'src/app/errorhandler/error-handler.service';
import { ErrorTypeEnum } from 'src/app/shared/enums.model';
import { toNumber } from 'lodash';

@Component({
    selector: 'cal-consuming-planning-area-mapping-component',
    templateUrl: './consuming-planning-area-mapping.component.html',
    styleUrls: ['./consuming-planning-area-mapping.component.css']
})

export class ConsumingPlanningAreaMappingComponent implements OnInit {
    resources: any = this.commonService.getResources();
    consumingPlanningAreaMappings: Observable<ConsumingPlanningAreaMapping[]>;
    consumingPlanningAreaMappingsConfig: CalsiumListConfig;
    isConsumingPlanningAreaMappingsLoading: boolean = true;
    paging: Observable<Paging>;
    totalCount: Observable<number>;
    subscriptions = new Subscription();
    isLoading: Observable<boolean>;
    sortingExpression: Observable<string>;
    filterExpression: Observable<string>;

    constructor(private consumingPlanningAreaMappingService: ConsumingPlanningAreaMappingStateService,
        private commonService: CommonStateService,
        private errHandler: ErrorHandlerService) { }

    ngOnInit() {
        this.isLoading = this.commonService.getLoading();
        this.paging = this.consumingPlanningAreaMappingService.getPaging();
        this.totalCount = this.consumingPlanningAreaMappingService.getTotalCount();
        this.sortingExpression = this.consumingPlanningAreaMappingService.getSortingExpression();
        this.filterExpression = this.consumingPlanningAreaMappingService.getFilterExpression();

        this.subscriptions.add(
            combineLatest(this.sortingExpression, this.filterExpression, this.paging)
                .pipe(
                    debounceTime(0),
                    switchMap(([sortingExpression, filterExpression, paging]) => {
                        return this.consumingPlanningAreaMappingService.loadConsumingPlanningAreaMappings(paging, sortingExpression, filterExpression);
                    })
                ).subscribe()
        );

        this.consumingPlanningAreaMappings = this.consumingPlanningAreaMappingService.getConsumingPlanningAreaMappings();
        this.buildList();
    }

    isEditable(item: ConsumingPlanningAreaMapping): boolean {
        return !item.to || item.to.length === 0;
    }

    saveEditedItem(formGroup, selectedCPA: ConsumingPlanningAreaMapping) {
        const payload: any = {
            id: toNumber(selectedCPA.id),
            to: formGroup.value.to,
            from: formGroup.value.from,
            validFrom: new Date(formGroup.value.validFrom).toISOString(),
            validTo: new Date(formGroup.value.validTo).toISOString()
        };

        if (!payload.to) {
            this.errHandler.emitError(this.resources.SetToConsumingPlanningArea, this.resources.InvalidConsumingPlanningArea, ErrorTypeEnum.Error);
            return of(false);
        }
        this.consumingPlanningAreaMappingService.addConsumingPlanningAreaMapping(payload).then((errors) => {
            if (errors && errors.length) {
                let errorMessage = '';
                for (const error of errors) {
                    if (error.includes('ALREADY_EXISTING_CPA_MAPPING')) {
                        errorMessage += this.resources.AlreadyExistingConsumingPlanningAreaMapping;
                    } else {
                        errorMessage += this.resources.InvalidConsumingPlanningAreaMapping;
                    }
                }
                this.errHandler.emitError(errorMessage, this.resources.Error, ErrorTypeEnum.Error);
            } else {
                this.errHandler.emitError(this.resources.ConsumingPlanningAreaMappingAddedSuccessfully, this.resources.Success, ErrorTypeEnum.Success);
            }
        });
        return of(true);
    }
    buildList() {
        this.consumingPlanningAreaMappingsConfig = new CalsiumListConfig({
            ShowFilterRow: true,
            ShowSumRow: false,
            AllowEdit: true,
            isRowEditable: this.isEditable,
            EditItemMethod: this.saveEditedItem,
            ListCaption: this.resources.ConsumingPlanningArea,
            ShowListCaption: true,
            ParentComponent: this
        });

        let column = this.consumingPlanningAreaMappingsConfig.AddListColumn(ColumnDataType.string, 'from', this.resources.From);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.consumingPlanningAreaMappingsConfig.AddListColumn(ColumnDataType.string, 'to', this.resources.To);
        column.AllowFilter = true;
        column.AllowEdit = true;
        column.Sortable = true;

        column = this.consumingPlanningAreaMappingsConfig.AddListColumn(ColumnDataType.date, 'validFrom', this.resources.ValidFrom);
        column.AllowFilter = true;
        column.Sortable = true;

        column = this.consumingPlanningAreaMappingsConfig.AddListColumn(ColumnDataType.date, 'validTo', this.resources.ValidTo);
        column.AllowFilter = true;
        column.Sortable = true;
    }

    onPaging(page: number) {
        this.consumingPlanningAreaMappingService.goToPage(page);
    }

    onSort(sortExpression: string) {
        this.consumingPlanningAreaMappingService.sort(sortExpression);
    }

    onFilter(filterExpression: string) {
        this.consumingPlanningAreaMappingService.filter(filterExpression);
    }

    onClearFilterAndSorting() {
        this.consumingPlanningAreaMappingService.clearFilterAndSorting();
    }
}
