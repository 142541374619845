import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map, lastValueFrom } from 'rxjs';

import { MaterialAddPrice } from '../../state/material/state-models/material-add-price';
import { CommonApiService } from '../common.service';
import { toNumber } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MaterialApiService {
  constructor(private apollo: Apollo,
    private commonService: CommonApiService) { }

  private materialQueryFields = `
      id
      materialNumber
      materialSupplyType {
        code
      }
      name
      currentManualPrice
      currentStandardPrice
      currentStrategicPrice
  `;
  materialQueryFieldsList = gql`
  query ($paging: Paging, $order: String, $filter: String) {
    materials {
      search(paging: $paging, order: $order, filter: $filter) {
        list {
          PartNumber: materialNumber
          Description: name
          PartType: materialSupplyTypeName
          Price: currentStandardPrice
          ManualPrice: currentManualPrice
          StrategicPrice: currentStrategicPrice
        }
      }
    }
  }`;

  private materialPriceQueryFields = `
      id
      materialId
      price
      validFrom
      validTo
      materialPriceType {
        id
        name
        priority
      }
      createdOn
      createdBy
      updatedOn
      updatedBy
      modifierComments
  `;

  getAllMaterials(variables?): Promise<any> {
    const result$ = this.apollo
      .query<any>({
        variables,
        query: gql`
                query($paging: Paging, $order:String, $filter:String)
                {
                  materials
                  {
                    search(paging: $paging, order: $order, filter: $filter)
                    {
                      list {
                        id
                        materialNumber
                        materialSupplyTypeCode                        
                        name
                        currentManualPrice
                        currentStandardPrice
                        currentStrategicPrice
                        modifierComments                      
                      }
                      totalCount
                    }
                  }
                }`
      })
      .pipe(
      
      map((result: any) => {
          return result?.data?.materials?.search;
        })
      )

    return lastValueFrom(result$);
  }

  exportMaterialParts(fileName: string, variables?: any): Promise<any> {
    return this.commonService.exportQuery(this.materialQueryFieldsList, variables, fileName);
  }

  getMaterialById(id: number, variables?): Promise<any> {
    const result$ = this.apollo
      .query<any>({
        variables: { ...variables, id },
        query: gql`
                query($id: Int, $order: String, $filter: String) {
                  materials {
                    material(id: $id) {
                      list {
                        ${this.materialQueryFields}
                        purchaserCode
                        weightInGrams
                        pricedUnitOfMeasurementName
                        materialPrices (order: $order, filter: $filter) {
                          ${this.materialPriceQueryFields}
                        }
                      }
                    }
                  }
                }`
      })
      .pipe(

        map((result: any) => {
          return result?.data?.materials?.material?.list;
        })
      )

    return lastValueFrom(result$);
  }

  getMaterialPriceTypes(): Promise<any> {
    const result$ = this.apollo
      .query<any>({
        query: gql`
                {
                  materials {
                    materialPriceType {
                      id
                      name
                      priority
                    }
                  }
                }`
      })
      .pipe(

        map((result: any) => {
          return result?.data?.materials;
        })
      )

    return lastValueFrom(result$);
  }

  async addMaterialPrice(input: any): Promise<any> {
    const payload = {
      id: toNumber(input.id),
      materialId: toNumber(input.materialId),
      materialPriceTypeId: toNumber(input.materialPriceTypeId),
      validFrom: input.validFrom ? new Date(input.validFrom).toISOString() : null,
      validTo: input.validTo ? new Date(input.validTo).toISOString() : null,
      modifierComments: input.modifierComments,
      price: toNumber(input.price)
    };
    const mutation = `
          mutation ($payload: MaterialPriceInput!) {
            materialPricesMutation {
              updateMaterialPrice(payload: $payload) {
                ${this.materialPriceQueryFields}
              }
            }
          }`;

    return this.apollo.mutate({
      mutation: gql(mutation),
      variables: { payload }
    })
      .toPromise();
  }
}