import { Injectable } from '@angular/core';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { AppState } from '../app.state';
import {
    LoadComponentDetailsAction,
    ChangeComponentMaterialTotalCountAction,
    ChangeComponentMaterialPageAction,
    LoadComponentDetailsMaterialsAction,
    ChangeComponentMaterialSortExpressionAction,
    ChangeComponentMaterialFilterExpressionAction,
    LoadComponentDetailsSpecificationsAction,
    ChangeComponentSpecificationsTotalCountAction,
    ChangeComponentSpecificationsSortExpressionAction,
    ChangeComponentSpecificationsFilterExpressionAction,
    ChangeComponentSpecificationsPageAction,
    ChangeComponentMaterialShowZeroQuantityPartsAction,
} from './actions';

import { ComponentIndividualApiService } from '../../api/services/component-individual-api.service';
import { Paging } from '..';
import { Loadable } from '../common/loadable';
import { ComponentIndividual } from '../flow/state-models/component-individual';
import { ComponentIndividualMaterial } from './state-models/component-individual-material';
import { ProductIndividualApiService } from '../../api/services/product-individual-api.service';
import { ProductSpecification } from './state-models/product-specification';
import { ComponentDetailsState } from './state';

@Injectable()
export class ComponentDetailsStateService {

    constructor(
        private appStore: Store<AppState>,
        private appState: State<AppState>,
        private componentApiService: ComponentIndividualApiService,
        private productApiService: ProductIndividualApiService) { }

    getComponents(): Observable<ComponentIndividual[]> {
        return this.appStore
            .select(state => state.component.component)
            .pipe(map(flows => _.values(flows)));
    }
    // =============================================
    @Loadable()
    async loadComponentDetailsData(id: number): Promise<void> {
        const response = await this.componentApiService.getComponentIndividualById(id);
        this.appStore.dispatch(new LoadComponentDetailsAction(response[0]));
    }

    @Loadable()
    async loadComponentDetailsMaterials(componentIndividualId: number, paging: Paging, sortExpression: string, filterExpression: string, showZeroQuantityParts: boolean): Promise<void> {
        this.appStore.dispatch(new LoadComponentDetailsMaterialsAction([]));
        this.appStore.dispatch(new ChangeComponentMaterialTotalCountAction(0));

        const payload = {
            componentIndividualId,
            showZeroQuantityParts,
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression
        };
        const response = await this.componentApiService.getComponentIndividualMaterial(payload);
        this.appStore.dispatch(new LoadComponentDetailsMaterialsAction(response.list));
        this.appStore.dispatch(new ChangeComponentMaterialTotalCountAction(response.totalCount));
    }

    showZeroQuantityParts(showZeroQuantityParts: boolean) {
        this.appStore.dispatch(new ChangeComponentMaterialShowZeroQuantityPartsAction(showZeroQuantityParts));
    }

    getShowZeroQuantityParts(): Observable<boolean> {
        return this.appStore.select(state => state.componentDetails.showZeroQuantityParts);
    }


    getComponentDetails(): Observable<ComponentIndividual> {
        return this.appStore.select(state => state.componentDetails.componentDetails);
    }

    getComponentMaterials(): Observable<ComponentIndividualMaterial[]> {
        return this.appStore.select(state => state.componentDetails.componentMaterial);
    }

    getComponentMaterialsSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.componentDetails.componentMaterialSortExpression);
    }

    sortComponentMaterials(sortExpression: string) {
        this.appStore.dispatch(new ChangeComponentMaterialSortExpressionAction(sortExpression));
    }

    getComponentMaterialsFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.componentDetails.componentMaterialFilterExpression);
    }

    filterComponentMaterials(filterExpression: string) {
        this.appStore.dispatch(new ChangeComponentMaterialFilterExpressionAction(filterExpression));
    }

    getComponentMaterialPaging(): Observable<Paging> {
        return this.appStore.select(state => state.componentDetails.componentMaterialPaging);
    }

    goToComponentMaterialPage(page: number) {
        this.appStore.dispatch(new ChangeComponentMaterialPageAction(page));
    }

    getComponentMaterialTotalCount(): Observable<number> {
        return this.appStore.select(state => state.componentDetails.componentMaterialTotalCount);
    }

    @Loadable()
    async loadComponentSpecification(productIndividualId: number, paging: Paging, sortExpression: string, filterExpression: string): Promise<void> {
        const payload = {
            productIndividualId: _.toNumber(productIndividualId),
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression
        };
        const response = await this.productApiService.getProductIndividualSpecification(payload);
        const action = new LoadComponentDetailsSpecificationsAction(response.list);
        this.appStore.dispatch(action);

        const totalCountAction = new ChangeComponentSpecificationsTotalCountAction(response.totalCount);
        this.appStore.dispatch(totalCountAction);
    }

    getComponentSpecification(): Observable<ProductSpecification[]> {
        return this.appStore.select(state => state.componentDetails.componentSpecification);
    }

    getComponentSpecificationSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.componentDetails.componentSpecificationSortExpression);
    }

    sortComponentSpecification(sortExpression: string) {
        this.appStore.dispatch(new ChangeComponentSpecificationsSortExpressionAction(sortExpression));
    }

    getComponentSpecificationFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.componentDetails.componentSpecificationFilterExpression);
    }

    filterComponentSpecification(filterExpression: string) {
        this.appStore.dispatch(new ChangeComponentSpecificationsFilterExpressionAction(filterExpression));
    }

    getComponentSpecificationPaging(): Observable<Paging> {
        return this.appStore.select(state => state.componentDetails.componentSpecificationPaging);
    }

    goToComponentSpecificationPage(page: number) {
        this.appStore.dispatch(new ChangeComponentSpecificationsPageAction(page));
    }

    getComponentSpecificationTotalCount(): Observable<number> {
        return this.appStore.select(state => state.componentDetails.componentSpecificationTotalCount);
    }

    exportComponentMaterials(componentIndividualId: number): Promise<any> {
        const payload = {};
        payload['componentIndividualId'] = componentIndividualId;
        const fileName = `${componentIndividualId}_Materials`;
        return this.componentApiService.exportComponentIndividualMaterial(payload, fileName);
    }
}
