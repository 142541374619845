import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { toNumber } from 'lodash';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ComponentAllocationApiService } from 'src/api/services/component-allocation-api.service';
import { CalsiumListColumnItem, CalsiumListConfig, ColumnDataType } from 'src/app/calsiumlist/calsiumlist.component.model';
import { CalsiumDateFormatter } from 'src/app/shared/calsium-date-formatter';
import { CalsiumNumberFormatterPipe } from 'src/app/shared/calsium-number.pipe';
import { TextAlignment } from 'src/app/shared/enums.model';
import { ComponentAllocation, ComponentAllocationInput, MasterDataStateService, Paging, ScaniaUnit } from 'src/state';
import { CommonStateService } from 'src/state/common/service';
import { ComponentSearchForm } from 'src/state/flow/state-models/component-search-form';
import { ReplacementComponentListStateService } from 'src/state/replacement-component-list/service';

@Component({
  selector: 'cal-replacement-components',
  templateUrl: './replacement-components.component.html',
  styleUrls: ['./replacement-components.component.css']
})
export class ReplacementComponentsComponent implements OnInit {

  selectedScaniaUnitId: number = 0;
  resources: any = this.commonService.getResources();
  componentSearchForm: Observable<ComponentSearchForm>;
  searchComponentsForm: UntypedFormGroup;
  scaniaUnitList: Observable<ScaniaUnit[]>;
  fileName: string = '';

  dataList: Observable<ComponentAllocation[]>;
  listConfig: CalsiumListConfig;
  paging: Observable<Paging>;
  totalCount: Observable<number>;
  errorCount: Observable<number>;
  subscriptions = new Subscription();
  isLoading: Observable<boolean>;
  sortingExpression: Observable<string>;
  filterExpression: Observable<string>;


  constructor(
    private componentAllocationApiService: ComponentAllocationApiService,
    private commonService: CommonStateService,
    private masterDataService: MasterDataStateService,
    private formBuilder: UntypedFormBuilder,
    private replacementComponentService: ReplacementComponentListStateService
  ) { }

  ngOnInit() {
    this.fillSearchForm();
    this.scaniaUnitList = this.masterDataService.getScaniaUnitsByTypes(['Final Assembly', 'Allocation']);
    this.isLoading = this.commonService.getLoading();

    this.sortingExpression = this.replacementComponentService.getSortingExpression();
    this.filterExpression = this.replacementComponentService.getFilterExpression();
    this.totalCount = this.replacementComponentService.getTotalCount();
    this.errorCount = this.replacementComponentService.getErrorCount();
    this.paging = this.replacementComponentService.getPaging();
    this.dataList = this.replacementComponentService.getList();

    let pageInitialLoad = true;
    this.subscriptions.add(
      combineLatest(this.paging, this.sortingExpression, this.filterExpression).pipe(
        debounceTime(0),
        switchMap(([paging, sortingExpression, filterExpression]) => {
          if (!pageInitialLoad) {
            return this.replacementComponentService.loadReplacementComponentList(
              this.searchComponentsForm.value.selectedScaniaUnit,
              this.searchComponentsForm.value.startDate,
              this.searchComponentsForm.value.endDate,
              paging,
              sortingExpression,
              filterExpression,
              true
            );
          }
          pageInitialLoad = false;
          return new Promise<void>(null);
        })
      ).subscribe()
    );
    this.buildList();
  }

  ngOnDestroy() { this.subscriptions.unsubscribe(); }

  fillSearchForm(fromDate: Date = null, toDate: Date = null, scaniaUnit: string = '') {
    this.searchComponentsForm = this.formBuilder.group({
      startDate: [CalsiumDateFormatter.getDateOrDefault(fromDate, true), []],
      endDate: [CalsiumDateFormatter.getDateOrDefault(toDate, false), []],
      selectedScaniaUnit: [scaniaUnit, <any>Validators.required]
    });
  }

  onScaniaUnitSelected(scaniaUnitId: any) {
    this.selectedScaniaUnitId = scaniaUnitId;
  }

  onExportToExcel() {
    const fromDate = this.searchComponentsForm.value.startDate;
    const toDate = this.searchComponentsForm.value.endDate;
    const scaniaUnitId = this.searchComponentsForm.value.selectedScaniaUnit;
    const scaniaUnit = this.masterDataService.getState().scaniaUnits.find(su => su.id === parseInt(scaniaUnitId));
    const payload = {
      from: new Date(fromDate).toISOString(),
      to: new Date(toDate).toISOString(),
      allocationScaniaUnit: toNumber(scaniaUnitId),
      isReplacementComponent: true
    }
    this.fileName = `${scaniaUnit.name}_${fromDate}_${toDate}_replacementComponents`;
    return this.componentAllocationApiService.exportReplacementComponentAllocationListForSpecificFinalAssembly(payload, this.fileName);
  }

  buildList() {
    this.listConfig = new CalsiumListConfig();
    this.listConfig.ListCaption = this.resources.List;
    this.listConfig.ShowFilterRow = true;
    this.listConfig.ParentComponent = this;
    this.listConfig.ShowExportToExcel = true;

    let column;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'chassisNumber', this.resources.ChassisNumber);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.string, 'popId', this.resources.PopId);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.date, 'productionDate', this.resources.ProductionDate);
    column.AllowFilter = true;
    column.Sortable = true;

    column = this.listConfig.AddListColumn(ColumnDataType.boolean, 'calculationCompleted', this.resources.CalculationStatus);
    column.AllowFilter = true;
    column.Sortable = true;
    column.TextAlignment = TextAlignment.Center;
    column.FilterBooleanTrueText = this.resources.Completed;
    column.FilterBooleanFalseText = this.resources.NotCompleted;

    column = this.listConfig.AddListColumn(ColumnDataType.number, 'totalMaterialValue', this.resources.MaterialValue);
    column.AllowFilter = true;
    column.Sortable = true;
    column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
    column.TextAlignment = TextAlignment.Right;

    column = this.listConfig.AddListColumn(ColumnDataType.number, 'totalProductionValue', this.resources.ProductionValue);
    column.AllowFilter = true;
    column.Sortable = true;
    column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
    column.TextAlignment = TextAlignment.Right;

    column = this.listConfig.AddListColumn(ColumnDataType.number, 'totalValue', this.resources.TotalValue);
    column.AllowFilter = true;
    column.Sortable = true;
    column.DisplayPipe = new CalsiumNumberFormatterPipe().setDecimalPlaces(0, 0);
    column.TextAlignment = TextAlignment.Right;
  }

  onPaging(page: number) {
    this.replacementComponentService.goToPage(page);
  }

  onSort(sortExpression: string) {
    this.replacementComponentService.sort(sortExpression);
  }

  onFilter(filterExpression: string) {
    this.replacementComponentService.filter(filterExpression);
  }

  onClearFilterAndSorting() {
    this.replacementComponentService.clearFilterAndSorting();
  }

  onSearch() {
    const fromDate = this.searchComponentsForm.value.startDate;
    const toDate = this.searchComponentsForm.value.endDate;
    const scaniaUnitId = this.searchComponentsForm.value.selectedScaniaUnit;

    return this.replacementComponentService.refetchList(
      scaniaUnitId,
      fromDate,
      toDate);
  }  
}
